import React, { useState, useEffect } from "react";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import CommonDashboard from "./CommonDashboard";
import { camelCase, formatFein } from "./DashboardUtils";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const CommonTableParent = () => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [detailedPanel, setDetailedPanel] = useState([]);

  useEffect(() => {
    let columnsData = [
      {
        title: "Company Name",
        field: "companyName",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "2px 0px 2px 12px",
          minWidth: "200px",
          maxWidth: "410px",
        },
        headerStyle: {
          padding: " 0px 0px 0px 10px",
          minWidth: "200px",
          maxWidth: "410px",
        },
        filterCellStyle: {
          padding: "5px 0px 5px 4px",
        },
        render: (rowData) => (
          <Clamp lines={2}>
            <span>
              <div
                onClick={() => onClickCompanyName(rowData)}
                className="company-name"
              >
                {camelCase(rowData?.companyName)}
              </div>
            </span>
          </Clamp>
        ),
      },
      {
        title: "State",
        field: "state",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "2px 0px 2px 6px",
          minWidth: "70px",
        },
        headerStyle: {
          padding: " 0px 0px 0px 10px",
          minWidth: "70px",
        },

        render: (rowData) => <Clamp lines={1}>{rowData?.state || "-"}</Clamp>,
      },
      {
        title: "FEIN",
        field: "fein",
        filterPlaceholder: "Filter",
        customFilterAndSearch: (term, rowData, field) =>
          customFilterAll(term, rowData, field),

        cellStyle: {
          wordBreak: "break-all",
          padding: "2px 0px 2px 6px",
          minWidth: "100px",
        },
        headerStyle: {
          padding: "10px 0",
          minWidth: "100px",
        },
        render: (rowData) => (
          <Clamp lines={1}>{formatFein(rowData?.fein) || ""}</Clamp>
        ),
      },
      {
        title: "Payroll",
        field: "payroll",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "2px 0px 2px 6px",
          minWidth: "120px",
        },
        headerStyle: {
          padding: " 0px 0px 0px 10px",
          minWidth: "120px",
        },

        render: (rowData) => <Clamp lines={1}>{rowData?.payroll}</Clamp>,
      },
      {
        title: "Effective Date",
        field: "effectiveDate",
        filtering: true,
        filterPlaceholder: "Filter",
        render: (rowData) => rowData?.effectiveDate,

        headerStyle: {
          padding: "0px 0px 0px 8px",
          minWidth: "70px",
        },
        cellStyle: {
          padding: "2px 0px 2px 6px",
          minWidth: "70px",
          width: "70px",
        },
        customFilterAndSearch: (term, rowData, field) =>
          customFilterAll(term, rowData, field),
      },
    ];
    let rowDataList = []
    let detailedPanelData = [
      {
        tooltip: "Client info",
        icon: () => <KeyboardArrowRightIcon />,
        render: (rowData) => {
          return <TabDetailPanel rowData={rowData} />;
        },
      },
    ];
    let TabDetailPanel = ({ rowData }) => {
      return (
        <div style={{ padding: "25px" }}>
          <TableContainer component={Paper}>
            <Table size="small" className="sub_tab1" id="pre_engaged_subtab">
              <TableHead
                style={{
                  background: "#00000066",
                  color: "whitesmoke",
                }}
              >
                <TableRow>
                  <TableCell>Contact Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Job Description</TableCell>
                  <TableCell>Visit Count</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.email_specific ? (
                  Object.values(rowData.email_specific).map(
                    (element, index) => {
                      let step;
                      if (element.companyProfile) {
                        if (Number(element.proposalGeneratedCount) > 0) {
                          step = "Proposal Generated";
                        } else if (element.quote_status) {
                          if (element.quote_status === "uwportal") {
                            step = "UW Portal";
                          } else if (element.quote_status === "proceed_uw") {
                            step = "Approved";
                          } else if (element.quote_status === "reject") {
                            step = "Rejected";
                          }
                        } else if (Number(element.quoteGeneratedCount) > 0) {
                          step = "Quote Generated";
                        } else if (element.formStage) {
                          if (element.formStage === "two") {
                            if (element.nonEligibilityList) {
                              step = "Not Eligible";
                            } else {
                              step = "UW Questions";
                            }
                          } else if (element.formStage === "one") {
                            step = "Company Profile";
                          }
                        } else if (
                          element.visitCount &&
                          Number(element.visitCount) > 0
                        ) {
                          step = "Landing Page";
                        } else {
                          step = "-";
                        }
                        if (
                          element.companyProfile.status &&
                          element.companyProfile.status.value
                        ) {
                          step =
                            statusPriorityMap[
                              element.companyProfile.status.value
                            ] < statusPriorityMap[step]
                              ? element.companyProfile.status.value
                              : step;
                        }
                        return (
                          <TableRow key={index}>
                            <TableCell scope="row">
                              {element.companyProfile &&
                              element.companyProfile.contact_name
                                ? element.companyProfile.contact_name.value
                                : ""}
                            </TableCell>
                            <TableCell scope="row">
                              {element?.companyProfile?.phoneNumber?.value?.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                "($1) $2-$3"
                              ) ||
                                element?.companyProfile?.contact_number?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                ""}
                            </TableCell>
                            <TableCell scope="row">
                              {element?.companyProfile?.emailId?.value}
                            </TableCell>
                            <TableCell scope="row">
                              {element?.companyProfile?.job_title?.value}
                            </TableCell>
                            <TableCell scope="row">
                              {element.visitCount || 0}
                            </TableCell>
                            <TableCell scope="row">{step}</TableCell>
                          </TableRow>
                        );
                      }
                    }
                  )
                ) : (
                  <TableRow key={0}>
                    <TableCell scope="row">
                      {rowData?.contactName || "-"}
                    </TableCell>
                    <TableCell scope="row">
                      {rowData.phoneNumber
                        ? rowData.phoneNumber.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell scope="row">
                      {rowData?.userEmail || "-"}
                    </TableCell>
                    <TableCell scope="row">{""}</TableCell>
                    <TableCell scope="row">
                      {rowData.visitCount || "-"}
                    </TableCell>
                    {/* <TableCell scope="row">
              {rowData.latestCampaign || "-"}
            </TableCell> */}
                    <TableCell scope="row">{rowData.step || "-"}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    };
    setColumns(columnsData);
    setData(rowDataList);
    setDetailedPanel(detailedPanelData);
  }, []);

  return (
    <CommonDashboard
      columns={columns}
      data={data}
      detailedPanel={detailedPanel}
    />
  );
};

export default CommonTableParent;
