import axios from "axios";
import { awsUrl, awsUrl2, awsAcordUpload } from "../config";
import { getHeader } from "../utils/common";
export function uploadDocs(postDataS3) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/uploadDocs", postDataS3, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function uploadRenewalFile(postDataS3) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/uploadRenewalFile", postDataS3, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function putExtensisRenewalTable(dataToSend) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/putExtensisRenewalTable", dataToSend, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function upload_file_status(userEmail) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + `/api/upload_file_status/getData`,
        JSON.stringify({
          email: userEmail,
          client: "renewal",
          uploadType: ["first", "second"],
        }),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function raterFileData(dataToSend, type) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + `/api/raterFileData/${type}`, dataToSend, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function fileUploadAcord(formData) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsAcordUpload + "/api/fileUploadAcord", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function ocr_doc_demo_acord(fileName) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(
        awsAcordUpload +
          `/api/s3PollerJson/ocr-doc-demo-acord/${fileName}/us-east-1`,
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function deleteFilefromS3(fileName) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/deleteFilefromS3",
        JSON.stringify({ key: removedFile }), header 
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function dashboardFileDownload(file) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/dashboardFileDownload",
        JSON.stringify({
          fileName: file,
        }),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function uwDashboardFileDownload(file) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl + "/api/uwDashboardFileDownload",
        JSON.stringify({
          fileName: file,
        }),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
