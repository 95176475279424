import axios from "axios";
import { awsUrl, awsUrl2, } from "../config";
import { getHeader } from "../utils/common";

export function uploadDashboardComments(postDataS3) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getSalesDashboardDataAdmin() {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl2 + "/api/getSalesDashboardDataAdmin", header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getDashboardTableData(requestBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/getDashboardTableData", requestBody, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getNonEligibleData() {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl2 + "/api/getNonEligibleData", header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function peoCampaignData() {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/peoCampaignData/getAll", {}, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function removeSalesPersonFlag(recordDetails) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/removeSalesPersonFlag", recordDetails, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function fetHubspotcompanyContactDetails(requestBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/onDashboardCompanyNameClick",
        requestBody,
        header
      )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}


export function salespersonDashboard(requestBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
       awsUrl2 + '/api/company/SalespersonDashboard',
        requestBody,
        header
      )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

