export const fontfunciton = () => {
  if (window.innerWidth < 1600 && window.innerWidth > 1500) {
    return "0.84rem";
  }
  if (window.innerWidth < 1500 && window.innerWidth > 1410) {
    return "0.8rem";
  }
  if (window.innerWidth < 1410 && window.innerWidth > 1310) {
    return "0.76rem";
  }
  if (window.innerWidth < 1300) {
    return "0.72rem";
  }
};

export const camelCase = (str) => {
  if (!str) return "";
  return str
    .trim()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const dateSort = (a, b, field) => {
  let momentA = moment(a[field], "M/D/YY");
  let momentB = moment(b[field], "M/D/YY");

  if ((!momentA.isValid() && momentB.isValid()) || momentA.isBefore(momentB)) {
    return -1;
  } else if (
    (momentA.isValid() && !momentB.isValid()) ||
    momentA.isAfter(momentB)
  ) {
    return 1;
  } else {
    return 0;
  }
};

export const customFilterAll = (term, rowData, field) => {
  let customFieldName = field["field"];

  return rowData[customFieldName]
    ?.toString()
    ?.toLowerCase()
    ?.startsWith(term?.toLowerCase());
};

export const formatFein = (value) => {
  if (!value) return "-";
  let fein = "-";
  value = value?.split("-")?.join("");
  var len = value?.length,
    dummystr = "000000000";

  if (len === 0) {
    fein = "-";
  } else if (len > 0 && len < 9) {
    value = dummystr.slice(0, 9 - len) + "" + value;
    fein = value[0] + value[1] + "-" + value?.slice(2);
  } else {
    fein = value[0] + value[1] + "-" + value?.slice(2);
  }

  return fein;
};
