import React, { useEffect, useState } from "react";
import styles from "./CommonDashboard.module.scss";
import { NumericFormat } from "react-number-format";
import SmallLoader from "../../common/SmallLoader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { awsUrl2 } from "../../../config";
import Header from "../../common/Header";
import { useLibertateContext } from "../../../context/ContextProvider";

let stateKeyMap = {
  alabama: "AL",
  alaska: "AK",
  arizona: "AZ",
  arkansas: "AR",
  california: "CA",
  colorado: "CO",
  connecticut: "CT",
  delaware: "DE",
  florida: "FL",
  georgia: "GA",
  hawaii: "HI",
  idaho: "ID",
  illinois: "IL",
  indiana: "IN",
  iowa: "IA",
  kansas: "KS",
  kentucky: "KY",
  louisiana: "LA",
  maine: "ME",
  maryland: "MD",
  massachusetts: "MA",
  michigan: "MI",
  minnesota: "MN",
  mississippi: "MS",
  missouri: "MO",
  montana: "MT",
  nebraska: "NE",
  nevada: "NV",
  new_hampshire: "NH",
  new_jersey: "NJ",
  new_mexico: "NM",
  new_york: "NY",
  north_carolina: "NC",
  north_dakota: "ND",
  ohio: "OH",
  oklahoma: "OK",
  oregon: "OR",
  pennsylvania: "PA",
  rhode_island: "RI",
  south_carolina: "SC",
  south_dakota: "SD",
  tennessee: "TN",
  texas: "TX",
  utah: "UT",
  vermont: "VT",
  virginia: "VA",
  washington: "WA",
  west_virginia: "WV",
  wisconsin: "WI",
  wyoming: "WY",
};

const CommonDashboard = ({}) => {
  let LibertateContext = useLibertateContext();
  const navigate = useNavigate();
  const [rowDataList, setRowDataList] = useState([]);
  const [total, setTotal] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loader, setLoader] = useState(true);
  const [theme, setTheme] = useState("light");
  const [sortingTable, setSortingTable] = useState(true);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [filterOptions, setFilterOption] = useState({});

  useEffect(() => {
    const requireParams = { after: "0", limit: "10", ...filterOptions };
    getHubspotMQLData(requireParams);
    setPageNumber(1);
  }, [filterOptions]);

  const filterOptionsHandler = async (e) => {
    let { name, value } = e.target;
    setFilterOption((prevState) => {
      console.log(prevState, "prevState");
      let updatedFilterOption = { ...prevState, [name]: value };
      return updatedFilterOption;
    });
  };

  const getHubspotMQLData = async (requireParams) => {
    try {
      setLoader(true);
      const requestData = await axios.post(
        awsUrl2 +"/api/getPreUWData",
        requireParams
      );
    let {prepareFinalResponse,total }= requestData.data.response
      console.log(requestData.data);
      setRowDataList(prepareFinalResponse);
      setTotal(total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const requireParams = { after: "0", limit: "10", ...filterOptions };
    getHubspotMQLData(requireParams);
  }, []);

  const pageNumberHandle = async (event) => {
    const { id, value } = event.target;
    let newPageNumber = pageNumber;

    try {
      setLoader(true);

      if (id === "prev" && pageNumber > 1) {
        newPageNumber = pageNumber - 1;
      } else if (id === "next" && pageNumber < Math.ceil(total / 10)) {
        newPageNumber = pageNumber + 1;
      } else if (id === "firstPage" && pageNumber !== 1) {
        newPageNumber = 1;
      } else if (id === "lastPage" && pageNumber < Math.ceil(total / 10)) {
        newPageNumber = Math.ceil(total / 10);
      } else if (id === "typedValue" && value) {
        newPageNumber = parseInt(value, 10);
        if (
          isNaN(newPageNumber) ||
          newPageNumber < 1 ||
          newPageNumber > Math.ceil(total / 10)
        ) {
          newPageNumber = pageNumber;
        }
      }

      setPageNumber(newPageNumber);
      let newSortDirection = sortingTable ? "ASCENDING" : "DESCENDING";
      const requireParams = {
        after: (newPageNumber - 1) * 10,
        limit: "10",
        clickedPage: id,
        ...filterOptions,
        sortPropertyName: selectedSort,
        sortPropertyDirection: newSortDirection,
      };

      if (pageNumber !== newPageNumber) {
        await getHubspotMQLData(requireParams);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const sortingDashboardHandler = async (columnName) => {
    setLoader(true);
    let newSortDirection;
    setSortingTable((prevState) => {
      return !prevState;
    });
    newSortDirection = sortingTable ? "DESCENDING" : "ASCENDING";
    console.log(newSortDirection, sortingTable);
    const requireParams = { after: "0", limit: "10", ...filterOptions };
    await getHubspotMQLData({
      ...requireParams,
      sortPropertyName: columnName,
      sortPropertyDirection: newSortDirection,
    });
    setLoader(false);
  };

  const SortingIcon = (columnName) => (
    <span onClick={() => sortingDashboardHandler(columnName)}>
      {sortingTable ? (
        <i className="bi bi-sort-up m-1"></i>
      ) : (
        <i className="bi bi-sort-down m-1"></i>
      )}
    </span>
  );

  const onCompanyNameClickHandler = async (companyId) => {
    LibertateContext.startLoader();
    navigate(`/Quote?user=${companyId}`);
  };
 

  //   // const [contactList, setContactList] = useState({
  //   //   "001": {
  //   //     emailIdList: [
  //   //       {
  //   //         contactName: "Sageer",
  //   //         phoneNumber: "9085195153",
  //   //         Email: "111sageer111@gmail.com",
  //   //         jobDescription: "software Engineer II",
  //   //         visitCount: "2",
  //   //         status: "opened",
  //   //       },
  //   //       {
  //   //         contactName: "Sageer",
  //   //         phoneNumber: "9085195153",
  //   //         Email: "111sageer111@gmail.com",
  //   //         jobDescription: "software Engineer II",
  //   //         visitCount: "2",
  //   //         status: "opened",
  //   //       },
  //   //       {
  //   //         contactName: "Sageer",
  //   //         phoneNumber: "9085195153",
  //   //         Email: "111sageer111@gmail.com",
  //   //         jobDescription: "software Engineer II",
  //   //         visitCount: "2",
  //   //         status: "opened",
  //   //       },
  //   //     ],
  //   //     enableRow: true,
  //   //   },
  //   //   "002": {
  //   //     emailIdList: [
  //   //       {
  //   //         contactName: "Sageer",
  //   //         phoneNumber: "9085195153",
  //   //         Email: "111sageer111@gmail.com",
  //   //         jobDescription: "software Engineer II",
  //   //         visitCount: "2",
  //   //         status: "opened",
  //   //       },
  //   //     ],
  //   //     enableRow: true,
  //   //   },
  //   // });
  //   // // to add the detail panel and call API call
  //   // const addNewRowHandler = (name, rowId) => {
  //   //   setContactList((prevState) => {
  //   //     let updatedContact = {
  //   //       ...prevState,
  //   //       [rowId]: {
  //   //         ...prevState?.[rowId],
  //   //         ["emailIdList"]: [...prevState?.[rowId]?.["emailIdList"]],
  //   //         enableRow: true,
  //   //       },
  //   //     };
  //   //     return updatedContact;
  //   //   });
  //   // };
  //   // //  to hide the detial panel
  //   // const hideRowDataHandler = (rowId) => {
  //   //   setContactList((prevState) => {
  //   //     let updatedContact = {
  //   //       ...prevState,
  //   //       [rowId]: {
  //   //         ...prevState?.[rowId],
  //   //         ["emailIdList"]: [...prevState?.[rowId]?.["emailIdList"]],
  //   //         enableRow: false,
  //   //       },
  //   //     };
  //   //     return updatedContact;
  //   //   });
  //   // };

  //   // add new contact to dashboard
  //   // const addNewContactHandler = () => {
  //   //   setContactList((prevState) => {
  //   //     let updatedContact = {
  //   //       ...prevState,
  //   //       [rowId]: {
  //   //         ...prevState?.[rowId],
  //   //         ["emailIdList"]: [...prevState?.[rowId]?.["emailIdList"]],
  //   //         enableRow: true,
  //   //       },
  //   //     };
  //   //     return updatedContact;
  //   //   });
  //   // };
  //   // add the new row to the below tr tag in dashboard
  //   //   {<tr className={`${index + "level"} table-responsive`}>
  //   //   {contactList &&
  //   //     Object.entries(contactList).map(
  //   //       ([ele, value], ind) => {
  //   //         console.log(value, "aswer");
  //   //         if (
  //   //           row?.companyId == ele &&
  //   //           value?.enableRow === true
  //   //         ) {
  //   //           return (
  //   //             <>
  //   //               <td colspan="5">
  //   //                 <table
  //   //                   className={`table table-hover ${styles.detial_talbe} `}
  //   //                 >
  //   //                   <thead>
  //   //                     <th> Contact Name</th>
  //   //                     <th> Phone Number</th>
  //   //                     <th> Email</th>
  //   //                     <th>Job Description</th>
  //   //                     <th>Visit count</th>
  //   //                     <th>Status</th>
  //   //                   </thead>
  //   //                   <tbody>
  //   //                     {(value?.["emailIdList"]).map(
  //   //                       (detailItem, indx) => {
  //   //                         return (
  //   //                           <tr>
  //   //                             <td>
  //   //                               {detailItem?.contactName ||
  //   //                                 "-"}
  //   //                             </td>
  //   //                             <td>
  //   //                               {detailItem?.phoneNumber ||
  //   //                                 "-"}
  //   //                             </td>
  //   //                             <td>
  //   //                               {detailItem?.Email ||
  //   //                                 "-"}
  //   //                             </td>
  //   //                             <td>
  //   //                               {detailItem?.jobDescription ||
  //   //                                 "-"}
  //   //                             </td>
  //   //                             <td>
  //   //                               {detailItem?.visitCount ||
  //   //                                 "-"}
  //   //                             </td>
  //   //                             <td>
  //   //                               {detailItem?.status ||
  //   //                                 "-"}
  //   //                             </td>
  //   //                             <td>
  //   //                               <button
  //   //                                 className="btn btn-success"
  //   //                                 onClick={() => {
  //   //                                   addNewContactHandler(
  //   //                                     row.companyId
  //   //                                   );
  //   //                                 }}
  //   //                               >
  //   //                                 Add +
  //   //                               </button>
  //   //                             </td>
  //   //                           </tr>
  //   //                         );
  //   //                       }
  //   //                     )}
  //   //                   </tbody>
  //   //                 </table>
  //   //               </td>
  //   //             </>
  //   //           );
  //   //         }
  //   //       }
  //   //     )}
  //   // </tr>}
  //   // {contactList?.[row?.companyId]?.["enableRow"] ? (
  //   //   <td
  //   //     onClick={() =>
  //   //       hideRowDataHandler(row.companyId)
  //   //     }
  //   //   >
  //   //     &#8964;
  //   //   </td>
  //   // ) : (
  //   //   <td
  //   //     onClick={() =>
  //   //       addNewRowHandler("companyId", row.companyId)
  //   //     }
  //   //   >{`>`}</td>
  //   // )}

  return (
    <div>
      <Header/>
    <div className="container-fluid mt-3">
    <div className={styles.preuw_header} ><h4>PreUW Dashboard</h4></div>
      <div className={styles.table_wrapper}>
        <div id={styles.CommonTable}>
          <div className="d-flex justify-content-end">
            <div className={styles.left_right_arrow}>
              <i
                className="bi bi-chevron-bar-left"
                id="firstPage"
                onClick={pageNumberHandle}
                title={"First Page"}
                style={{ cursor: 'pointer' }}
              ></i>
              <i
                className="bi bi-chevron-compact-left"
                id="prev"
                onClick={pageNumberHandle}
                disabled={pageNumber === 1}
                title={"Previous Page"}
                style={{ cursor: 'pointer' }}
              ></i>
              <NumericFormat
                value={`${pageNumber}`}
                id="typedValue"
                allowNegative={false}
                style={{
                  width: "60px",
                  textAlign: "center",
                  height: "35px",
                  border: "none",
                  borderBottom: "2px solid black",
                }}
                onBlur={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (value >= 1 && value <= Math.ceil(total / 10)) {
                    pageNumberHandle(e);
                  } else {
                    e.target.value = pageNumber;
                  }
                }}
                disabled={loader}
              />
              <span>{`1 - ${total > 10 ? Math.ceil(total / 10) : "1"}`}</span>
              <i
                className="bi bi-chevron-compact-right"
                id="next"
                onClick={pageNumberHandle}
                title={"Next Page"}
                style={{ cursor: 'pointer' }}
              ></i>
              <i
                className="bi bi-chevron-bar-right"
                id="lastPage"
                onClick={pageNumberHandle}
                title={"Last Page"}
                style={{ cursor: 'pointer' }}
              ></i>
            </div>
          </div>
          <hr></hr>
          <div className="table-responsive">
            <table
              className={`table ${
                theme === "light" ? styles.table_light : styles.table_dark
              }`}
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    id="companyName"
                    onMouseEnter={() => setHoveredColumn("name")}
                    onMouseLeave={() => setHoveredColumn(null)}
                    onClick={() => setSelectedSort("name")}
                    className={styles.column1}
                  >
                    Company Name
                    <span>
                      {(hoveredColumn === "name" || selectedSort === "name") &&
                        SortingIcon("name")}
                    </span>
                  </th>
                  <th
                    scope="col"
                    onMouseEnter={() => setHoveredColumn("createddate")}
                    onMouseLeave={() => setHoveredColumn(null)}
                    onClick={() => setSelectedSort("createddate")}
                    className={styles.column2}
                  >
                    Visited Date
                    <span>
                      {(hoveredColumn === "createddate" ||
                        selectedSort === "createddate") &&
                        SortingIcon("createddate")}
                    </span>
                  </th>
                  <th
                    scope="col"
                    onMouseEnter={() => setHoveredColumn("company_state")}
                    onMouseLeave={() => setHoveredColumn(null)}
                    onClick={() => setSelectedSort("company_state")}
                    className={styles.column3}
                  >
                    State
                    <span>
                      {(hoveredColumn === "company_state" ||
                        selectedSort === "company_state") &&
                        SortingIcon("company_state")}
                    </span>
                  </th>
                  <th
                    scope="col"
                    onMouseEnter={() => setHoveredColumn("state")}
                    onMouseLeave={() => setHoveredColumn(null)}
                    onClick={() => setSelectedSort("state")}
                    className={styles.column4}
                  >
                    state
                    <span>
                      {(hoveredColumn === "state" ||
                        selectedSort === "state") &&
                        SortingIcon("state")}
                    </span>
                  </th>
                  <th
                    scope="col"
                    onMouseEnter={() => setHoveredColumn("city")}
                    onMouseLeave={() => setHoveredColumn(null)}
                    onClick={() => setSelectedSort("city")}
                    className={styles.column5}
                  >
                    city
                    <span>
                      {(hoveredColumn === "city" || selectedSort === "city") &&
                        SortingIcon("city")}
                    </span>
                  </th>
                  <th
                    scope="col"
                    onMouseEnter={() => setHoveredColumn("phone")}
                    onMouseLeave={() => setHoveredColumn(null)}
                    onClick={() => setSelectedSort("phone")}
                    className={styles.column6}
                  >
                    phone
                    <span>
                      {(hoveredColumn === "phone" ||
                        selectedSort === "phone") &&
                        SortingIcon("phone")}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th className={styles.column1}>
                    <input
                      className={styles.filter_columns }
                      name="companyNameFilter"
                      value={filterOptions?.["companyNameFilter"] || ""}
                      onChange={filterOptionsHandler}
                    
                    />
                  </th>
                  <th className={styles.column2}>
                    <input
                      className={styles.filter_columns}
                      name="visitedDate"
                      value={filterOptions?.["visitedDate"] || ""}
                      onChange={filterOptionsHandler}
                    />
                  </th>

                  <th className={styles.column3} >
                    <input
                      className={styles.filter_columns}
                      name="stateFilter"
                      value={filterOptions?.["stateFilter"] || ""}
                      onChange={filterOptionsHandler}
                    />
                  </th>

                  <th className={styles.column4}></th>
                  <th className={styles.column5}></th>
                  <th className={styles.column6}></th>
                </tr>
              </thead>
              {loader ? (
                <tbody className={styles.empty_dashboad_loader}>
                  <div className={styles.dashboard_loader}>
                    Loading...
                    <SmallLoader />
                  </div>
                </tbody>
              ) : rowDataList && rowDataList.length > 0 ? (
                <tbody>
                  {rowDataList.map((row, index) => (
                    <tr key={index} id={index + "row"}>
                      <td className={styles.column1}>
                        <span
                          className={styles.dashboardCompanyName}
                          onClick={() => {
                            onCompanyNameClickHandler(row?.hs_object_id);
                          }}
                        >
                          {row?.name}
                        </span>
                      </td>
                      <td className={styles.column2}>{row?.createdate}</td>
                      <td className={styles.column3}>{stateKeyMap?.[row?.company_state]}</td>
                      <td className={styles.column4}>{row?.state}</td>
                      <td className={styles.column5}>{row?.city}</td>
                      <td className={styles.column6}>{row?.phone}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className={styles.empty_dashboad_loader}>
                  <div className={styles.dashboard_loader}>No Data Found</div>
                </tbody>
              )}
            </table>
          </div>
          <hr></hr>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CommonDashboard;
