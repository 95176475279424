import React from "react";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import GenericDropZoneHelper from "./GenericDropZoneHelper";
import { awsUrl2 } from "../../../config";
import { getHeader } from "../../../utils/common";
import { uploadDocs } from "../../../api/dropzoneApi";
import styles from "./GenericDropZone.module.scss";

LoadingOverlay.propTypes = undefined

export default class GenericDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.uploadDoc !== prevProps.uploadDoc) {
      if (this.props.uploadDoc === true) {
        this.setState({ uploadingInProgress: true });
        if (this.props.parent === true)
          this.handleUpload();
      } else {
        this.setState({ uploadingInProgress: false });
      }
    }
  }

  updateFileList = (name, newFile) => {
    let { fileList } = this.props;
    if (fileList[name][newFile.name] === "#empty") {
      this.setState({ errMsg: "File already exist", uploadMsg: undefined });
      return;
    }
    fileList[name][newFile.name] = newFile;
    this.setState({ errMsg: undefined, uploadMsg: undefined });
    this.props.setFileList(fileList);
  };

  removeFile = (name, fileName) => {
    let { fileList } = this.props;
    delete fileList[name][fileName];
    this.props.setFileList(fileList);
  };

  uploadFile = (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      uploadDocs(postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve();
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadDocs ", error);
          reject();
        });
    });
  };

  updateDataTable = (apiName, dataToSend) => {
    return new Promise(async (resolve, reject) => {
      let header = await getHeader();
      axios
        .post(awsUrl2 + `/api/${apiName}`, dataToSend, header)
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          console.log("error in API-updateDataTable", error);
          reject();
        });
    });
  };

  handleUpload = async () => {
    let { fileKeyName, upload_file_details, folder1, folder2, setUploadingFileFlag, fileList, setFileList } = this.props;
    let keys = Object.keys(fileList);
    try {
      let promiseList = [],
        fileListToStore = [];

      for (let key in fileList) {
        if (fileList[key]) {
          for (let fileName in fileList[key]) {
            let fileType = fileName.split(".").reverse()[0];

            fileListToStore.push(
              {
                key: `${folder1}/${folder2}/${fileName}`,
                fileType,
                keyToFile: key
              }
            );

            const postDataS3 = {
              folder1,
              folder2: key,
              fileName,
              fileType,
            };
            if (fileList[key][fileName] !== "#empty")
              promiseList.push(
                this.uploadFile(fileList[key][fileName], postDataS3)
              );
          }
        }
      }

      try {
        let uploadedResponse = await Promise.all(promiseList);

        if (uploadedResponse?.length) {
          let uploadFilePromiseList = [];

          for (let file_data of upload_file_details) {
            let request_body = JSON.parse(
              JSON.stringify(file_data.request_body)
            );
            request_body[fileKeyName] = fileListToStore;

            uploadFilePromiseList.push(
              this.updateDataTable(file_data.api_name, request_body).catch(
                (error) => console.log("error in API", error)
              )
            );
          }

          Promise.all(uploadFilePromiseList)
            .then((res) => {
              this.setState({
                uploadingInProgress: false,
                uploadMsg: "files uploaded",
                errMsg: undefined,
              });
              for (let key of keys)
                setUploadingFileFlag(key)(false);

              for (let key in fileList) {
                if (fileList[key]) {
                  for (let fileName in fileList[key]) {
                    fileList[key][fileName] = "#empty"
                  }
                }
              }

              setFileList(fileList);
            })
            .catch((err) => console.log(err));
        } else {
          throw "no data";
        }
      } catch (error) {
        console.log("error: ", error);
        this.setState({
          uploadingInProgress: false,
          errMsg: "Error",
          uploadMsg: undefined,
        });
        for (let key of keys)
          setUploadingFileFlag(key)(false);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  render() {
    let { uploadingInProgress, uploadMsg, errMsg } = this.state;
    let { fileList, folder2, heading } = this.props;

    return (
      <LoadingOverlay active={uploadingInProgress} spinner>
        <div className={styles.dropZonesContainer}>
          <GenericDropZoneHelper
            name={folder2}
            fileList={fileList}
            updateFileList={this.updateFileList}
            removeFile={this.removeFile}
            heading={heading}
            uploadMsg={uploadMsg}
          />
          <div className="d-flex flex-column">
            <pre
              className={
                "text-center text-capitalize " +
                (uploadMsg ? "text-success" : "text-danger")
              }
            >
              {uploadMsg || errMsg}
            </pre>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
