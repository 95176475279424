"use strict";
import React, { forwardRef, useEffect, useState, useRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import MaterialTable from "material-table";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Search from "@mui/icons-material/Search";
import Clear from "@mui/icons-material/Clear";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Check from "@mui/icons-material/Check";
import AddBox from "@mui/icons-material/AddBox";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import Remove from "@mui/icons-material/Remove";
import ViewColumn from "@mui/icons-material/ViewColumn";
import SaveAlt from "@mui/icons-material/SaveAlt";
import { TablePagination } from "@mui/material";
import parse from "html-react-parser";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import styles from "./SalespersonDashboard.module.scss";
import downloadImage from "../../../images/file-download-circle-green.svg";
import Header from "../../common/Header";
import { salespersonDashboard } from "../../../api/dashboardApi";
import { useNavigate } from "react-router-dom";
let ismobile = window.innerWidth < 1600;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: () => <></>,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// Fein format and adding 0 precedence
const formatFein = (value) => {
  if (!value) return "-";
  let fein = "-";
  value = value.split("-").join("");
  var len = value.length,
    dummystr = "000000000";

  if (len === 0) {
    fein = "-";
  } else if (len > 0 && len < 9) {
    value = dummystr.slice(0, 9 - len) + "" + value;
    fein = value[0] + value[1] + "-" + value.slice(2);
  } else {
    fein = value[0] + value[1] + "-" + value.slice(2);
  }

  return fein;
};

// Camel case for companyName
const camelCase = (str) => {
  if (str)
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  return str;
};

const fontfunciton = () => {
  if (window.innerWidth < 1600 && window.innerWidth > 1500) {
    return "0.84rem";
  }
  if (window.innerWidth < 1500 && window.innerWidth > 1410) {
    return "0.8rem";
  }
  if (window.innerWidth < 1410 && window.innerWidth > 1310) {
    return "0.76rem";
  }
  if (window.innerWidth < 1300) {
    return "0.72rem";
  }
};



const SalespersonDashboard = (props) => {
  const navigate = useNavigate();
  const myRef = useRef();
  const [rowDataList, setRowDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllData();
  }, []);

  const onCompanyNameClickHandler = async (companyId) => {
    navigate(`/Quote?user=${companyId}`);
  };
  const columnData = [
    {
      title: "Company Name",
      field: "companyname",
      filterPlaceholder: "Filter",

      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "200px",
        wordBreak: "break-word",
      },
      headerStyle: {
        padding: "6px 5px 6px 10px",
      },
      render: (rowData) => {
        return (
          <Clamp lines={2}>
            <span  onClick= {()=>onCompanyNameClickHandler(rowData?.domain)}>
              
                {rowData?.companyname ? camelCase(rowData.companyname) : ""}
            
            </span>
          </Clamp>
        );
      },
    },
    {
      title: "FEIN",
      field: "fein",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      cellStyle: {
        wordBreak: "break-all",
        padding: "2px 0px 2px 6px",
        minWidth: "90px",
      },
      headerStyle: {
        padding: "10px 0",
        minWidth: "90px",
      },
      render: (rowData) => formatFein(rowData.fein),
    },
    {
      title: "Payroll",
      field: "payroll",
      filtering: true,
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "120px",
      },
      headerStyle: {
        padding: " 0px 0px 0px 10px",
        minWidth: "120px",
      },

      render: (rowData) => <Clamp lines={1}>{rowData?.payroll || "-"}</Clamp>,
    },
  ];

  const customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  const setTableData = () => {
    // getting the data from backend
    const defaultMaterialTheme = createTheme();
    return (
      <div className={styles.materialTable_container}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            icons={tableIcons}
            data={rowDataList}
            columns={columnData}
            options={{
              stickyHeader: true,
              filtering: false,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 75, 100],
              headerStyle: {
                fontSize: ismobile ? fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
            }}
            isLoading={isLoading}
          />
        </ThemeProvider>
      </div>
    );
  };

  const getAllData = async () => {
    try {
      let rowDataList = [];
      let last_key;
      let indx = 0;
      let req_body = {
        first_time_loading: "true",
      };
      do {
        if (last_key) req_body["lastEvaluatedKey"] = last_key;

        if (indx === 1) delete req_body["first_time_loading"];

        await new Promise((resolve, reject) => {
          salespersonDashboard(req_body)
            .then((res) => {
              let dataFromAPI = res.data.response.list;
              let domainButtonMap = {};
              console.log(dataFromAPI);
              try {
                for (let domain in dataFromAPI) {
                  let createPreparedData = true;
                  let preparedData = {};

                  for (let domainWiseQuoteData of dataFromAPI[domain]) {
                    let payroll;
                    let classcodeInfo;
                    let sum = 0;

                    let childloc =
                      domainWiseQuoteData?.["currProspectDetails"]?.[
                        "childrenLoc"
                      ] ||
                      domainWiseQuoteData?.["childrenLoc"] ||
                      {};

                    for (let domainWiseQuoteData in childloc) {
                      let userChildlocData = childloc[domainWiseQuoteData];
                      classcodeInfo = userChildlocData.classCodesInfo;
                      classcodeInfo = Object.entries(classcodeInfo);
                      for (let i = 0; i < classcodeInfo.length; i++) {
                        let paydata = classcodeInfo[i];
                        const stringAmount = paydata[1]?.payroll?.value;
                        if (!stringAmount) continue;
                        const numberAmount = parseFloat(
                          stringAmount.replace(/[^\d.-]/g, "")
                        );
                        sum = sum + numberAmount;
                      }
                      payroll = sum;
                    }

                    if (createPreparedData) {
                      try {
                        preparedData = {
                          domain: domainWiseQuoteData?.domain,

                          submittedDate:
                            (domainWiseQuoteData?.submissionDate
                              ? moment(
                                  Number(domainWiseQuoteData?.submissionDate),
                                  "x"
                                ).format("M/D/YY")
                              : "-") || "",
                          companyname:
                            domainWiseQuoteData?.currProspectDetails
                              ?.companyProfile?.companyName?.value ||
                            domainWiseQuoteData?.["companyProfile"]?.[
                              "companyName"
                            ]["value"] ||
                            "",
                          fein:
                            domainWiseQuoteData?.currProspectDetails
                              ?.companyProfile?.fein?.value ||
                            domainWiseQuoteData?.["companyProfile"]?.["fein"][
                              "value"
                            ] ||
                            "",

                          currProspect:
                            domainWiseQuoteData?.currProspectDetails || {},
                          payroll: payroll?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                          }),
                        };
                        createPreparedData = false;
                      } catch (error) {}
                    }
                  }

                  rowDataList.push(preparedData);
                }
              } catch (error) {}
              setRowDataList(rowDataList);
              console.log(rowDataList, "rowDatalist");
              setIsLoading(false);
              resolve();
            })
            .catch((err) => {
              setRowDataList([]);
              setIsLoading(false);
              reject();
            });
        });

        indx += 1;
      } while (indx === 1 || last_key);
    } catch (error) {
      console.log(
        error,
        "error while fetching the data hubspotdemoTrackingtalbe "
      );
    }
  };
  return (
    <>
      <div>
        <Header />
        <div>
          <h3 className={styles.table_header_name}> Sales Person Table</h3>
        </div>
        <div className={styles.submission_uw_dashboard}>
          <div ref={myRef}>{setTableData()}</div>
        </div>
      </div>
    </>
  );
};

export default SalespersonDashboard;
