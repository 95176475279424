import React from "react";
import CustomDropZone from "./CustomDropZone2";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { Auth } from "aws-amplify";
import Papa from "papaparse";
import { parseInputData } from "../../utils/parseInputData";
import {
  uploadRenewalFile,
  putExtensisRenewalTable,
  upload_file_status,
} from "../../api/dropzoneApi";
import styles from "./DropZones.module.scss";
const moment = require("moment");

export default class DropZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: {},
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
      histFileList: {},
      histUploadingInProgress: false,
      histUploadMsg: undefined,
      histErrMsg: undefined,
      selectedTab: "profile",
      dataFromAPI: {
        first: [],
        second: [],
      },
      intervalID: undefined,
    };
  }

  async componentDidMount() {
    // sessionStorage.clear();
    // try {
    //   let intervalID = setInterval(async () => {
    //     await this.fetchFilesData();
    //   }, 5000);
    //   this.setState({ intervalID });
    // } catch (error) {
    //   console.log("error in re-calling the table API");
    // }
  }

  componentWillUnmount() {
    try {
      clearInterval(this.state.intervalID);
    } catch (error) {
      console.log("error in unmount interval removal");
    }
  }

  updateFileList = (newFile, flag) => {
    let { fileList, histFileList } = this.state;
    let files;
    if (flag === "first") {
      files = fileList;
    } else {
      files = histFileList;
    }
    if (files[newFile.name] && files[newFile.name] === "#empty") {
      flag === "first"
        ? this.setState({ errMsg: "File already exist", uploadMsg: undefined })
        : this.setState({
            histErrMsg: "File already exist",
            histUploadMsg: undefined,
          });
      return;
    }
    files[newFile.name] = newFile;

    if (flag === "first") {
      fileList = files;
    } else {
      histFileList = files;
    }

    this.setState({
      fileList,
      histFileList,
      errMsg: undefined,
      uploadMsg: undefined,
      histErrMsg: undefined,
      histUploadMsg: undefined,
    });
  };

  removeFile = (fileName, flag) => {
    let { fileList, histFileList } = this.state;
    let files;
    if (flag === "first") {
      files = fileList;
    } else {
      files = histFileList;
    }

    delete files[fileName];

    if (flag === "first") {
      fileList = files;
    } else {
      histFileList = files;
    }

    this.setState({ fileList, histFileList });
  };

  uploadFile = async (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      uploadRenewalFile(postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve(returnData.url);
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  putExtensisRenewalTable = (dataToSend, flag) => {
    return new Promise((resolve, reject) => {
      putExtensisRenewalTable(dataToSend)
        .then((res) => {
          flag === "first"
            ? this.setState({
                uploadMsg: "files uploaded",
                errMsg: undefined,
              })
            : this.setState({
                histUploadMsg: "files uploaded",
                histErrMsg: undefined,
              });
          resolve();
        })
        .catch((error) => {
          console.log("error in API-putExtensisRenewalTable", error);
          reject();
        });
    });
  };

  handleUpload = async () => {
    try {
      let { fileList } = this.state;
      this.setState({ uploadingInProgress: true });

      console.log("++files", fileList);
      let files = Object.keys(fileList);
      if (files.length > 0) {
        Papa.parse(fileList[files[0]], {
          header: true,
          complete: (results) => {
            let validateEmodState = {};
            for (let key in results.data) {
              const stateValue = results?.data?.[key]?.["Location State"];
              const lowercaseState = stateValue?.toLowerCase();

              validateEmodState[lowercaseState] = {};
              validateEmodState[lowercaseState]["emod"] =
                results?.data?.[key]?.["EMOD"];
              validateEmodState[lowercaseState]["emodDate"] =
                results?.data?.[key]?.["Emod Effective Date"];
            }
            let parsedData;
            parseInputData(results.data)
              .then((res) => {
                parsedData = res;
                sessionStorage.clear();
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify({ ...parsedData })
                );
                sessionStorage.setItem(
                  "validateEmodState",
                  JSON.stringify({ validateEmodState })
                );
                this.setState({ uploadingInProgress: false });
                window.location.href = "/Quote";
              })
              .catch((error) => console.log(error));
          },
        });
      } else {
        console.log("++ no file uploaded");
        this.setState({ uploadingInProgress: false });
      }
    } catch (error) {
      console.log("error: ", error);
      this.setState({
        uploadingInProgress: false,
        uploadMsg: undefined,
        errMsg: "error in uploading",
      });
    }
  };

  validate = (file, flag) => {};

  setSelectedState = (val) => {
    this.setState({ selectedTab: val });
  };

  fetchFilesData = async () => {
    let isLoggedIn = await Auth.currentAuthenticatedUser();
    let userEmail = isLoggedIn.attributes.email;
    let res = await upload_file_status(userEmail);
    this.setState({ dataFromAPI: res.data });
  };

  render() {
    let {
      fileList,
      uploadingInProgress,
      uploadMsg,
      errMsg,
      histFileList,
      histUploadingInProgress,
      histUploadMsg,
      histErrMsg,
      dataFromAPI,
      selectedTab,
    } = this.state;
    return (
      <>
        <div className={styles.dropZonesContainer}>
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6 p-4">
              <LoadingOverlay active={uploadingInProgress} spinner>
                <CustomDropZone
                  fileList={fileList}
                  updateFileList={this.updateFileList}
                  removeFile={this.removeFile}
                  heading={<b>Company Data</b>}
                  uploadMsg={uploadMsg}
                  flag={"first"}
                  downloadFileName={"Company Profile Template.csv"}
                />
                <DropButton
                  uploadMsg={uploadMsg}
                  errMsg={errMsg}
                  handleUpload={this.handleUpload}
                  uploadingInProgress={uploadingInProgress}
                  flag={"first"}
                />
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const DropButton = (props) => {
  return (
    <div className="d-flex flex-column">
      <pre
        className={
          "text-center text-capitalize " +
          (props.uploadMsg ? "text-success" : "text-danger")
        }
      >
        {props.uploadMsg || props.errMsg}
      </pre>
      <button
        className={`btn btn-warning btn-sm btn-warning-shadow upload-docs ${styles.uploadButton}`}
        onClick={() => props.handleUpload(props.flag)}
        disabled={props.uploadingInProgress}
      >
        Upload
      </button>
    </div>
  );
};

const TabsForDropzones = (props) => {
  return (
    <div className="row table-tabs mx-5 mt-5 mb-0 tabs-for-dropzones">
      <ul
        className="nav justify-content-center nav-pills mb-4"
        id="myTab"
        role="tablist"
      >
        <li className="nav-item mr-3" role="presentation">
          <button
            className={
              props.selectedTab === "profile" ? "nav-link active" : "nav-link"
            }
            id="company-profile-tab "
            type="button"
            role="tab"
            data-bs-toggle="tab"
            data-bs-target="#company-profile"
            aria-controls="company-profile"
            aria-selected={props.selectedTab === "profile" ? "true" : "false"}
            onClick={() => props.setSelectedState("profile")}
          >
            Company Profile Data
          </button>
        </li>
        <li className="nav-item mr-3" role="presentation">
          <button
            className={
              props.selectedTab === "historical"
                ? "nav-link active"
                : "nav-link"
            }
            id="historical-tab"
            type="button"
            role="tab"
            data-bs-toggle="tab"
            data-bs-target="#historical"
            aria-controls="historical"
            aria-selected={
              props.selectedTab === "historical" ? "true" : "false"
            }
            onClick={() => props.setSelectedState("historical")}
          >
            Historical Loss Data
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          class={
            props.selectedTab === "profile"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="company-profile"
          role="tabpanel"
          aria-labelledby="company-profile-tab"
        >
          <TableForTab data={props.dataFromAPI["first"]} />
        </div>
        <div
          class={
            props.selectedTab === "historical"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="historical"
          role="tabpanel"
          aria-labelledby="historical-tab"
        >
          <TableForTab data={props.dataFromAPI["second"]} />
        </div>
      </div>
    </div>
  );
};

const TableForTab = (props) => {
  let rowsData = props.data
    ? props.data.map((row, indx) => {
        return (
          <tr>
            <th scope="row">{indx + 1}</th>
            <td>
              {moment(Number(row.date))
                .utcOffset("-0500")
                .format("M/D/YYYY - hh:mm A")}
            </td>
            <td>{row.email_client.split("_")[0]}</td>
            <td>{row.key.split("/").reverse()[0]}</td>
            <td>{row.status}</td>
          </tr>
        );
      })
    : [];

  return (
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Date</th>
          <th scope="col">Email</th>
          <th scope="col">File name</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>{rowsData}</tbody>
    </table>
  );
};
