import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import UnderWriterFlow from "./component/pages/UnderWriterFlow/UnderWriterFlow";
import NewQuote from "./newcomponent/NewQuote";
import Login from "./component/pages/Login";
import DashboardLibertate from "./component/pages/DashboardLibertate";
import DashboardLibertateTab from "./component/pages/AgentDashboard/DashboardLibertateTab";
import DashboardRater from "./component/pages/DashboardRater";
import PrivateGroupRoute from "./component/common/PrivateGroupRoute";
import { NewRates } from "./newcomponent/NewRates/NewRates";
import DataUpload from "./component/pages/DataUpload";
import RaterUpload from "./component/pages/RaterUpload";
import RiskProfile from "./component/subcompo/sections-quote/RiskProfile/RiskProfile";
import DemoRouteHandler from "./component/pages/DemoRouteHandler";
import { LibertateContextProvider } from "./context/ContextProvider";
import { Auth } from "aws-amplify";
import CommonTableParent from "./component/pages/Common_dashboard/CommonTableParent";
import SalespersonDashboard from "./component/pages/SalesDashboard/SalespersonDashboard";
function App(props) {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      (async () => {
        try {
          let sessionData = await Auth.currentAuthenticatedUser();
          setSessionData(sessionData);
        } catch (error) {}
        setLoading(false);
      })();
    }
  }, []);

  return !loading ? (
    <LibertateContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login sessionData={sessionData} />} />

          <Route path="/demo" element={<DemoRouteHandler />} />

          <Route path="/riskprofile" element={<RiskProfile />} />

          <Route
            path="/RaterUpload"
            element={
              <PrivateGroupRoute
                allowedGroups={[process.env.REACT_APP_RATER_GROUP]}
                path="/RaterUpload"
                sessionData={sessionData}
              >
                <RaterUpload />
              </PrivateGroupRoute>
            }
          />

          <Route
            path="/Quote"
            element={<NewQuote sessionData={sessionData} />}
          />

          <Route
            path="/DataUpload"
            element={
              <PrivateGroupRoute
                allowedGroups={[
                  process.env.REACT_APP_UPLOAD_COMPANY,
                  process.env.REACT_APP_UPLOAD_RATER,
                ]}
                sessionData={sessionData}
              >
                <DataUpload sessionData={sessionData} />
              </PrivateGroupRoute>
            }
          />

          <Route
            path="/Dashboard"
            element={
              <PrivateGroupRoute
                allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                sessionData={sessionData}
              >
                <DashboardLibertateTab sessionData={sessionData} />
              </PrivateGroupRoute>
            }
          />

          <Route
            path="/Rates"
            element={
              <PrivateGroupRoute
                allowedGroups={[process.env.REACT_APP_RATER_GROUP]}
                sessionData={sessionData}
              >
                <NewRates sessionData={sessionData} />
              </PrivateGroupRoute>
            }
          />

          <Route
            path="/UwPortal"
            element={
              <PrivateGroupRoute
                allowedGroups={[process.env.REACT_APP_UW_GROUPS]}
                sessionData={sessionData}
              >
                <UnderWriterFlow sessionData={sessionData} />
              </PrivateGroupRoute>
            }
          />

          <Route
            path="/RaterDashboard"
            element={
              <PrivateGroupRoute
                allowedGroups={[process.env.REACT_APP_DASHBOARD_GROUP]}
                sessionData={sessionData}
              >
                <DashboardRater sessionData={sessionData} />
              </PrivateGroupRoute>
            }
          />
          <Route
            path="/preuwdashboard"
            element={
              <PrivateGroupRoute
                allowedGroups={[process.env.REACT_APP_PREUW_GROUP]}
                sessionData={sessionData}
              >
                <CommonTableParent sessionData={sessionData} />
              </PrivateGroupRoute>
            }
          />
          <Route
            path="/salesdashboard"
            element={
              <PrivateGroupRoute
                allowedGroups={[process.env.REACT_APP_SALESPERSON_GROUP]}
                sessionData={sessionData}
              >
                <SalespersonDashboard sessionData={sessionData} />
              </PrivateGroupRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </LibertateContextProvider>
  ) : (
    <></>
  );
}

export default App;
