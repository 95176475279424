import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { getEmodStatesData } from "../../../utils/form_compPro";
import NewProgress from "../../../newcomponent/common/NewProgress";
import UploadAcordFile from "../../../newcomponent/common/UploadAcordFile";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { companyProfileAddQuestions } from "../../../utils/ques_list";
import { getUserType } from "../../../utils/getUserType";
import carrierList from "../../../utils/carrierList.json";
import CompanyProfile from "../CompanyProfile/CompanyProfile";
import { LibertateContext } from "../../../context/Context";
import MinimizeComponent from "../MinimizeComponent/MinimizeComponent";
import LocationAndPayroll from "../LocationPayroll/LocationAndPayroll";
import Emod from "../Emod/Emod";
import { isDeepEquals } from "../../../utils/isDeepEquals";
import styles from "./FormCompPro.module.scss";
import {
  verifyOtp,
  sendOtpMail,
  getDomain,
  validateClassCodesAndDescription,
  getRiskProfileDataId,
  fetchRiskProfileData,
  sendContactDetailsToHubspot,
  sendCompanyDetailsToHubspot,
} from "../../../api/formCompProApi";

import {
  getExpModStateCategory,
  raterMcpStateData,
  getDomainInputData,
  getUserDataStatus,
  userTableData,
  getAmtrustData,
} from "../../../api/commonApi";
import ContactDetails from "../ContactDetails/ContactDetails";
import plusSymbol from "../../../images/plus4.png";
import SmallLoader from "../../common/SmallLoader";
const numeral = require("numeral");
const uuidv4 = require("uuid/v4");
const { carrierPeoProgramList } = carrierList;

class FormCompPro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      role: "",
      selDateBus: null,
      selDateEff: moment(),
      selDateExp: moment().add(1, "year"),
      header: {},
      uploadingFile: false,
      awaitingFile: false,
      error: false,
      errorMsg: "",
      urlSuccess: false,
      urlFail: false,
      basicInfoFail: false,
      stateInfoFail: false,
      signedUrl: "",
      companyProfileAddQuestions: companyProfileAddQuestions,
      state_class_code: {},
      sameAsPrimary: false,
      emodQuestions: {},
      otpVerified:
        sessionStorage.getItem("otpVerified") === "true" ? true : false,
      continueErr: "",
      userDetails: {},
      companyProfile: {
        companyName: {},
        descriptionOfOperations: {},
        firstName: {},
        lastName: {},
        yearsInBusiness: {},
        phoneNumber: {},
        entityType: {},
        expectedStartDate: { value: moment() },
        effectiveDate: { value: moment() },
        expectedExpiryDate: { value: moment().add(1, "year") },
        fein: {},
        street1: {},
        zipCode1: {},
        cityName1: {},
        state1: {},
        street2: {},
        zipCode2: {},
        cityName2: {},
        state2: {},
        producer: { value: "Paul Hughes" },
      },
      childrenLoc: {
        0: {
          number: 0,
          key: Math.random(),
          classCodesInfo: { 0: {} },
          state: {},
          zipCode: {},
          cityName: {},
          street: {},
          suta: {},
        },
      },
      peoDetails: { selectedPeo: "" },
      carrierList: { value: [], error: false },
      emodStatesData: {},
      email_specific: {},
      email_specificError: {},
      contact_loader: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    let { userData, userHubSpotData, setCurrProspect, setHideButtons } =
      this.props;

    if (!isDeepEquals(prevProps?.userData, userData)) {
      let prepareContactDetails = {};
      if (userData?.childrenLoc) this.setChildrenLoc(userData.childrenLoc);

      if (userData?.companyProfile)
        this.setCompanyProfile(null, null, userData.companyProfile);

      if (userData?.emodStatesData)
        this.setEmodStatesData(userData.emodStatesData);

      if (userData?.peoDetails) this.setPeoDetails(userData.peoDetails);

      if (userData?.carrierList) this.setCarrierList(userData.carrierList);
      if (userData?.quoteSubmitted) {
        let value = userData?.quoteSubmitted == "true" ? true : false;
        setHideButtons(value);
      }
      if (userData?.email_specific) {
        let index = 0;
        for (let key in userData?.email_specific) {
          prepareContactDetails[index] = userData?.email_specific[key];
          index++;
        }
        this.setEmailSpecificData(prepareContactDetails);
      }
      console.log(prepareContactDetails);

      let currProspect = {
        companyProfile: userData.companyProfile,
        emodStatesData: userData.emodStatesData,
        companyProfileAddQuestions: userData.addQuesData,
        riskProfile: userData.riskProfile,
        peoDetails: userData.peoDetails,
        carrierList: userData.carrierList,
        uwQues: userData.uwQues,
        childrenLoc: userData.childrenLoc,
        email_specific: prepareContactDetails || {},
      };
      setCurrProspect(currProspect);
    }

    // if no data found in both the table we get the detials from Hubspot
    if (!isDeepEquals(prevProps?.userHubSpotData, userHubSpotData)) {
      if (userHubSpotData?.childrenLoc)
        this.setChildrenLoc(userHubSpotData.childrenLoc);

      if (userHubSpotData?.companyProfile)
        this.setCompanyProfile(null, null, userHubSpotData.companyProfile);

      if (userHubSpotData?.emodStatesData)
        this.setEmodStatesData(userHubSpotData.emodStatesData);

      if (userHubSpotData?.peoDetails)
        this.setPeoDetails(userHubSpotData.peoDetails);

      if (userHubSpotData?.carrierList)
        this.setCarrierList(userHubSpotData.carrierList);
      if (userHubSpotData?.quoteSubmitted) {
        let value = userHubSpotData?.quoteSubmitted == "true" ? true : false;
        setHideButtons(value);
      }

      if (userHubSpotData?.email_specific) {
        this.setEmailSpecificData(userHubSpotData.email_specific);
      }

      let currProspect = {
        companyProfile: userHubSpotData?.companyProfile || {},
        emodStatesData: userHubSpotData?.emodStatesData || {},
        companyProfileAddQuestions: userHubSpotData?.addQuesData || {},
        riskProfile: userHubSpotData?.riskProfile || {},
        peoDetails: userHubSpotData?.peoDetails || {},
        carrierList: userHubSpotData?.carrierList || {},
        uwQues: userHubSpotData?.uwQues || {},
        childrenLoc: userHubSpotData?.childrenLoc || {},
        email_specific: userHubSpotData.email_specific || {},
      };
      setCurrProspect(currProspect);
    }
  };

  componentDidMount = async () => {
    const userDetails = await getUserType(this.props.sessionData.attributes);

    if (userDetails?.userType === "peo" && userDetails?.peoList?.length > 0) {
      this.setState({
        peoDetails: { selectedPeo: userDetails?.peoList?.[0].value },
        userDetails,
      });
    } else {
      this.setState({ userDetails });
    }

    //check if the primary and mailing address are the same
    let formStage = this.props.formStage;
    if (formStage && formStage !== "one") {
      let sameAsPrimary = this.checkSameAsPrimary();
      this.setState({ sameAsPrimary });
    }
  };
  setUWQues = (quesData) => {
    let { companyProfileAddQuestions } = this.state;
    for (let quesIndex in companyProfileAddQuestions) {
      if (quesData[quesIndex].resp === "Y") {
        companyProfileAddQuestions[quesIndex].response = true;
      } else {
        companyProfileAddQuestions[quesIndex].response = false;
      }
      companyProfileAddQuestions[quesIndex].remarks =
        quesData[quesIndex].remarks;
    }
    this.setState({ companyProfileAddQuestions });
  };

  updateQuesList = (e, key, value) => {
    let { companyProfileAddQuestions } = this.state;
    let index = e.target.id.split("-")[1];
    companyProfileAddQuestions[index][key] = value;
    this.setState({ companyProfileAddQuestions });
  };

  addYearToDate = (dt) => {
    o;
    dt.exp = Object.assign({}, dt);
    dt.exp.eff.add(1, "year");
    return dt;
  };
  handleDateChangeBus = (date) => {
    this.setState({
      selDateBus: date,
    });
  };

  updateFormFields = (data) => {
    let { companyProfile } = this.state;
    this.setState({
      companyProfile: { ...companyProfile, ...data.companyProfile },
      childrenLoc: data.childrenLoc,
    });
  };

  setInputData = (currProspect) => {
    let {
      childrenLoc,
      companyProfile,
      emodStatesData,
      requestedPricing,
      companyProfileAddQuestions,
      peoDetails,
      carrierList,
    } = currProspect;

    try {
      let _peoDetails = JSON.parse(JSON.stringify(peoDetails));
      let _carrierList = JSON.parse(JSON.stringify(carrierList));

      this.refreshEmodDetails(
        _peoDetails.selectedPeo === "insurecomp" ||
          _peoDetails.selectedPeo === "wcn",
        emodStatesData,
        childrenLoc,
        _peoDetails.selectedPeo,
        _carrierList
      );
    } catch (error) {}

    this.setState({ peoDetails: peoDetails || { selectedPeo: "" } }, () => {
      try {
        companyProfile.expectedStartDate.value = moment(
          companyProfile.expectedStartDate.value
        );
        companyProfile.effectiveDate.value = moment(
          companyProfile.effectiveDate.value
        );
      } catch (error) {
        console.log("eeee", error);
      }

      if (companyProfile?.fein?.value) {
        var value = companyProfile?.fein?.value.toString().trim();
        value = value.split("-").join("");
        var len = value.length,
          dummystr = "000000000";

        if (isNaN(value)) {
          companyProfile.fein.value = "";
        } else if (len > 0 && len < 9) {
          value = dummystr.slice(0, 9 - len) + "" + value;
          companyProfile.fein.value =
            value[0] + value[1] + "-" + value.slice(2);
          companyProfile.fein.errMsg = "";
        } else if (len > 9) {
          companyProfile.fein.errMsg = "FEIN needs to be 9 digits";
          companyProfile.fein.error = true;
        } else if (len === 0) {
          companyProfile.fein.value = undefined;
          companyProfile.fein.errMsg = "";
        } else {
          companyProfile.fein.value =
            value[0] + value[1] + "-" + value.slice(2);
          companyProfile.fein.errMsg = "";
        }
      }
      if (!companyProfile.producer) {
        companyProfile.producer = {};
        companyProfile.producer.value = "Paul Hughes";
      }

      if (requestedPricing) {
        this.setState({ requestedPricing });
      }
      if (companyProfileAddQuestions) {
        for (let question of companyProfileAddQuestions) {
          if (question.response === "Y") {
            question.response = true;
          } else {
            question.response = false;
          }
        }
        this.setState({ companyProfileAddQuestions });
      }

      this.setState(
        {
          childrenLoc,
          companyProfile,
          // emodStatesData,
          carrierList: carrierList || {},
        },
        () => {
          $("#loader").css("display", "none");
        }
      );
    });
  };

  checkSameAsPrimary = () => {
    let { companyProfile } = this.state;
    let primaryKeys = ["cityName", "street", "state", "zipCode"];
    let sameAsPrimary = true;
    for (let key of primaryKeys) {
      if (
        companyProfile?.[`${key}1`]?.value !==
        companyProfile?.[`${key}2`]?.value
      ) {
        sameAsPrimary = false;
        break;
      }
    }

    return sameAsPrimary;
  };

  setCompanyProfile = (key1, key2, val) => {
    if (key1 && key2) {
      this.setState((prevState) => ({
        companyProfile: {
          ...prevState.companyProfile,
          [key1]: { [key2]: val },
        },
      }));
    } else if (key1 && !key2) {
      this.setState((prevState) => ({
        companyProfile: { ...prevState.companyProfile, [key1]: val },
      }));
    } else if (!key1 && !key2) {
      this.setState({ companyProfile: val });
    }
  };

  setPeoDetails = (data) => {
    this.setState({ peoDetails: data });
  };

  setCarrierList = (data) => {
    this.setState({ carrierList: data });
  };

  setChildrenLoc = (data) => {
    this.setState({ childrenLoc: data });
  };

  setEmodStatesData = (data) => {
    this.setState({ emodStatesData: data });
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  refreshEmodDetails = (
    isLibPeoSelected,
    emodStatesData,
    childrenLoc,
    selectedPeo,
    selectedCarrList
  ) => {
    try {
      let _emodStatesData = JSON.parse(JSON.stringify(emodStatesData));
      let stateList = [],
        stateSet = {};
      for (let addressBlockNo in childrenLoc) {
        if (childrenLoc[addressBlockNo].state.value) {
          stateList.push(childrenLoc[addressBlockNo].state.value.toLowerCase());
          stateSet[childrenLoc[addressBlockNo].state.value] =
            childrenLoc[addressBlockNo].state.value;
        }
      }
      if (stateList.length > 0) {
        sessionStorage.setItem("stateSet", JSON.stringify(stateSet));

        if (isLibPeoSelected) {
          let statesData = {
            ncciStates: [],
            actingNcciStates: [],
            indBureauStates: [],
          };

          getExpModStateCategory(stateList)
            .then((response) => {
              statesData = response.data;
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              let newEmodData = getEmodStatesData(statesData, _emodStatesData);

              this.setEmodStatesData(newEmodData);
            });
        } else {
          let programList = [];

          let hasCarrier = (list, carr) => {
            for (let ele of list) {
              if (ele.value === carr) return true;
            }
            return false;
          };

          for (let carrierPeoProgramObj of carrierPeoProgramList) {
            if (
              (carrierPeoProgramObj.peo === selectedPeo ||
                (selectedPeo === "demo" &&
                  carrierPeoProgramObj.peo === "gms")) &&
              hasCarrier(selectedCarrList.value, carrierPeoProgramObj.carrier)
            )
              programList.push({ display_name: carrierPeoProgramObj.program });
          }

          raterMcpStateData(stateList, programList)
            .then((response) => {
              if (response?.data?.length > 0) {
                let validateEmodStateObject =
                  JSON.parse(sessionStorage.getItem("validateEmodState")) || "";
                for (let state of response.data) {
                  if (!(state in _emodStatesData)) {
                    let valueToUse = "";
                    if (
                      _emodStatesData?.["ncci"]?.["stateList"]?.includes(state)
                    ) {
                      valueToUse = _emodStatesData["ncci"]?.rate1?.value || "";
                    }
                    _emodStatesData[state] = {
                      rate1: {
                        value: valueToUse,
                      },
                      response: valueToUse ? true : false,
                      displayMore: false,
                      date1: { value: moment() },
                      date2: { value: moment() },
                    };
                  }
                  if (validateEmodStateObject) {
                    let valueToUse = "";
                    _emodStatesData[state] = {
                      rate1: {
                        value:
                          validateEmodStateObject?.["validateEmodState"]?.[
                            state
                          ]?.["emod"] || "",
                      },
                      response: valueToUse ? true : false,
                      displayMore: false,
                      date1: {
                        value: moment(
                          validateEmodStateObject?.["validateEmodState"]?.[
                            state
                          ]?.["emodDate"]
                        ),
                      },
                      date2: {
                        value: moment(
                          validateEmodStateObject?.["validateEmodState"]?.[
                            state
                          ]?.["emodDate"]
                        ),
                      },
                    };
                  }
                }
                if (_emodStatesData?.["ncci"]) {
                  delete _emodStatesData["ncci"];
                }
                for (let state in _emodStatesData) {
                  if (
                    !stateList.includes(state) ||
                    !response?.data?.includes(state)
                  ) {
                    delete _emodStatesData[state];
                  }
                }
              } else {
                _emodStatesData = {};
              }
              this.setEmodStatesData(_emodStatesData);
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      } else {
        this.setEmodStatesData({});
      }
    } catch (error) {
      console.log("Error in refresh Emod Details ", error);
    }
  };

  checkCompanyProfileError = () => {
    let { companyProfile } = this.state;
    let _companyProfile = JSON.parse(JSON.stringify(companyProfile));

    let fieldsToCheck = [
      "companyName",
      "descriptionOfOperations",
      "yearsInBusiness",
      "entityType",
      "expectedStartDate",
      "effectiveDate",
      "fein",
    ];

    let error = false;
    let field;
    for (let fieldName of fieldsToCheck) {
      if (
        !_companyProfile?.[fieldName]?.value ||
        _companyProfile?.[fieldName]?.error
      ) {
        _companyProfile[fieldName].error = true;
        if (!field) field = fieldName;
        error = true;
      }
    }
    this.setCompanyProfile(null, null, _companyProfile);
    return [error, field];
  };
  checkContactDetailsError = () => {
    const { email_specific, email_specificError } = this.state;
    let contactDetailsError = true;

    Object.entries(email_specific).forEach(([key, details], index) => {
      if (
        details?.companyProfile?.emailId?.value == "" ||
        details?.companyProfile?.emailId?.value === undefined ||
        details?.companyProfile?.emailId?.value === null
      ) {
        email_specificError[index] = {
          ...email_specificError[index],
          emailId: true,
        };
        contactDetailsError = false;
      }
      if (
        details?.companyProfile?.contact_name?.value == "" ||
        details?.companyProfile?.contact_name?.value == undefined ||
        details?.companyProfile?.contact_name?.value == null
      ) {
        email_specificError[index] = {
          ...email_specificError[index],
          contact_name: true,
        };
        contactDetailsError = false;
      }
      if (
        details?.companyProfile?.job_title?.value == "" ||
        details?.companyProfile?.job_title?.value == undefined ||
        details?.companyProfile?.job_title?.value == null
      ) {
        email_specificError[index] = {
          ...email_specificError[index],
          job_title: true,
        };
        contactDetailsError = false;
      }
      if (
        details?.companyProfile?.phoneNumber?.value === "" ||
        details?.companyProfile?.phoneNumber?.value === undefined ||
        details?.companyProfile?.phoneNumber?.value === null
      ) {
        email_specificError[index] = {
          ...email_specificError[index],
          phoneNumber: true,
        };
        contactDetailsError = false;
      }
    });

    console.log(email_specificError);
    this.setState({ email_specificError });
    return [contactDetailsError];
  };

  checkLocationInfoError = () => {
    let { childrenLoc } = this.state;
    let _childrenLoc = JSON.parse(JSON.stringify(childrenLoc));

    let error = false;
    let locationKeys = ["zipCode", "state", "cityName"];
    let classCodeKeys = ["classCodeDescription", "payroll"];
    let field;
    let index = 0;
    for (let addressBlockNo in _childrenLoc) {
      locationKeys.forEach((fieldName) => {
        if (
          !_childrenLoc[addressBlockNo][fieldName].value ||
          _childrenLoc[addressBlockNo][fieldName].error
        ) {
          _childrenLoc[addressBlockNo][fieldName].error = true;
          error = true;
          if (!field) field = "officeLoc" + addressBlockNo;
        }
      });
      for (let addressChildNo in _childrenLoc[addressBlockNo].classCodesInfo) {
        if (
          !_childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
        ) {
          _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"] =
            {};
          _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "ft"
          ].error = true;
          error = true;
          if (!field) field = "officeLoc" + addressBlockNo;
        } else if (
          !_childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
        ) {
          _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"] =
            {};
          _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "pt"
          ].error = true;
          error = true;
          if (!field) field = "officeLoc" + addressBlockNo;
        } else if (
          !(
            Number(
              _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
                .value
            ) ||
            Number(
              _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
                .value
            )
          )
        ) {
          if (
            !_childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
              .value
          )
            _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              "ft"
            ].error = true;
          if (
            !_childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
              .value
          )
            _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              "pt"
            ].error = true;
          error = true;
          if (!field) field = "officeLoc" + addressBlockNo;
        } else {
          _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "ft"
          ].error = false;
          _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "pt"
          ].error = false;
        }

        classCodeKeys.forEach((fieldName) => {
          if (
            !(
              fieldName in
              _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
            )
          ) {
            _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ] = { error: true };
            error = true;
            if (!field) field = "officeLoc" + addressBlockNo;
          } else if (
            !_childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].value ||
            _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error
          ) {
            _childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error = true;
            if (!field) field = "officeLoc" + addressBlockNo;
            error = true;
          }
        });
      }
      index++;
    }
    this.setChildrenLoc(_childrenLoc);
    return [error, field];
  };

  checkEmodInfoError = () => {
    let { emodStatesData } = this.state;
    let _emodStatesData = JSON.parse(JSON.stringify(emodStatesData));

    let error = false;
    for (let state in _emodStatesData) {
      if (_emodStatesData[state].response) {
        if (!_emodStatesData[state].rate1) {
          error = true;
          _emodStatesData[state].rate1 = { value: "", error: true };
        } else if (
          !_emodStatesData[state].rate1.value ||
          isNaN(_emodStatesData[state].rate1.value)
        ) {
          error = true;
          _emodStatesData[state].rate1.error = true;
        }
        if (_emodStatesData[state].displayMore) {
          if (!_emodStatesData[state].rate2) {
            error = true;
            _emodStatesData[state].rate2 = { value: "", error: true };
          } else if (
            !_emodStatesData[state].rate2.value ||
            isNaN(_emodStatesData[state].rate2.value)
          ) {
            error = true;
            _emodStatesData[state].rate2.error = true;
          }
        }
      }
    }

    return error;
  };

  validEmail = (e) => {
    let { companyProfile, otpVerified } = this.state;
    var email = e.target.value;
    var reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = reg.test(email);
    if (!isValid && email !== "") {
      companyProfile.emailId.error = true;
    } else {
      if (!otpVerified) {
        sendOtpMail(email)
          .then((res) => {
            this.setState({
              otpMessage:
                "A 4-digit verification code has been sent to your email.",
            });
            console.log("sent OTP successfully");
          })
          .catch((error) => {
            console.log("error while sending OTP");
          });
      }
    }
    this.setState({ companyProfile });
  };

  verifyOtpCode(email, otp) {
    // call the VerifyOtp method to get the APi.
    verifyOtp(email, otp)
      .then((res) => {
        if (res.data === "otp_verified") {
          this.setState({
            otpMessage: "Email verified successfully.",
            otpVerified: true,
          });
          this.fetchAndFillDetails();
        } else if (res.data === "otp_expired") {
          this.setState({
            otpMessage: "OTP expired.",
          });
        } else if (res.data === "invalid_otp") {
          this.setState({
            otpMessage: "Invalid OTP.",
          });
        } else if (res.data === "otp_not_generated") {
          this.setState({
            otpMessage: "OTP not generated.",
          });
        }
      })
      .catch((error) => {
        console.log("error while sending OTP");
        this.setState({
          otpMessage: "Unable to verify OTP.",
        });
      });
  }

  createRiskProfileBody = (childrenLoc, peoDetails, companyProfile) => {
    let riskProfileBody = {
      locationData: {},
      peo: peoDetails.selectedPeo === "demo" ? "gms" : peoDetails.selectedPeo,
      effectiveDate: moment(
        companyProfile.effectiveDate.value,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD"),
    };
    let stateLocMap = {};
    for (let addressBlockNo in childrenLoc) {
      let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
        childrenLoc[addressBlockNo].state.value
      }_${
        (childrenLoc[addressBlockNo].zipCode ||
          childrenLoc[addressBlockNo].zipcode)["value"]
      }`;

      let key = `${name}_${addressBlockNo}`;
      if (!(key in riskProfileBody.locationData)) {
        let state = key.split("_")[3].toLowerCase();
        if (state in stateLocMap) {
          key = stateLocMap[state];
        } else {
          riskProfileBody.locationData[key] = [];
          stateLocMap[state] = key;
        }
      }

      for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
        let classCodeDescArray =
          childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

        let code = classCodeDescArray.shift();
        let desc = classCodeDescArray.join(":").trim();
        let state = childrenLoc[addressBlockNo].state.value.toLowerCase();
        let payroll =
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
            .value;
        let ft =
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft.value;
        let pt =
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pt.value;

        riskProfileBody.locationData[key].push({
          code,
          description: desc,
          state,
          payroll,
          ft,
          pt,
        });
      }
    }

    return riskProfileBody;
  };

  checkPeoDetailsError = () => {
    let { peoDetails } = this.state;
    let peoDetailsCopy = { ...peoDetails };
    let response = false;

    try {
      if (peoDetailsCopy.selectedPeo) {
        response = false;
      } else {
        response = true;
      }
      peoDetailsCopy.error = response;
      this.setState({ peoDetails: peoDetailsCopy });
    } catch (error) {
      console.log("error", error);
      response = true;
    }

    return response;
  };

  checkCarrierList = () => {
    let { carrierList } = this.state;
    let _carrierList = { ...carrierList };

    let response = false;

    try {
      if (_carrierList.error || _carrierList.value.length === 0) {
        response = true;
      } else {
        response = false;
      }
      _carrierList.error = response;
      this.setState({ carrierList: _carrierList });
    } catch (error) {
      response = true;
    }

    return response;
  };
  // Amtrust createquote
  amtrustCreateQuoteResponse = (uuid, apiType) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let res = await getAmtrustData(uuid, apiType);
        console.log(
          res.data,
          "++++++++++++++++++++++++++++++++++++++++++++++++++++++"
        );
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  formatPhoneNumber = (data) => {
    try {
      let phoneNumberString = data;
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      if (cleaned) {
        let len = cleaned.length;
        for (let i = 1; i <= 10 - len; i++) {
          cleaned = "0" + cleaned;
        }
        return cleaned;
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error while formatting phone number", error);
      return data;
    }
  };

  submitCompPro = async (
    setUserData,
    setUserStatusData,
    sessionData,
    setFormStage,
    setCurrProspect,
    currProspect,
    stopLoader,
    startLoader,
    setContextData
  ) => {
    try {
      let {
        childrenLoc,
        companyProfile,
        emodStatesData,
        peoDetails,
        carrierList,
        email_specific,
        companyProfileAddQuestions,
      } = this.state;
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let companyId = urlParams.get("user") || sessionStorage.getItem("user");
      let _email_specific = {};
      let [companyProfileError, companyfield] = this.checkCompanyProfileError();
      let [locationInfoError, locationInfoField] =
        this.checkLocationInfoError();
      let [contactDetialsError] = this.checkContactDetailsError();
      // let peoDetailsError = this.checkPeoDetailsError();
      // let carrierListError = this.checkCarrierList();
      let uw_flow = sessionStorage.getItem("uw_flow") ? true : false;
      let _currProspect;
      // if (carrierListError) {
      //   document
      //     .getElementById("peo-select-container")
      //     .scrollIntoView({ behavior: "smooth" });
      //   stopLoader();
      // } else if (peoDetailsError) {
      //   document
      //     .getElementById("peo-select-container")
      //     .scrollIntoView({ behavior: "smooth" });
      //   stopLoader();
      // } else
      if (companyProfileError) {
        document.getElementById(companyfield).focus();
        stopLoader();
      } else if (locationInfoError) {
        document
          .getElementById(locationInfoField)
          .scrollIntoView({ behavior: "smooth" });
        stopLoader();
      } else if (!contactDetialsError) {
        // giving direct name of id to scroll up to contact detials
        document
          .getElementById("contact-details-container")
          .scrollIntoView({ behavior: "smooth" });
        stopLoader();
      }

      if (!companyProfileError && !locationInfoError && contactDetialsError) {
        if (companyId) {
          let updatedCompnaydetailshubspotResponse =
            await sendCompanyDetailsToHubspot(companyProfile, companyId);
        } else {
          conosle.log(
            "we need to creat the new company with generating the new company id. "
          );
        }

        // create  the  email_specific back to normal how we store in DB

        Object.entries(email_specific).forEach(([key, value]) => {
          const email = value?.companyProfile?.emailId?.value
            ? value?.companyProfile?.emailId?.value?.trim()
            : "";
          if (email !== "") {
            _email_specific[email] = {
              campaignMap: {},
              companyProfile: {
                companyName: {
                  value: value?.companyProfile?.company_name?.value || "",
                },
                contact_name: {
                  value: value?.companyProfile?.contact_name?.value || "",
                },
                emailId: {
                  value: email || "",
                },
                job_title: {
                  value: value?.companyProfile?.job_title?.value || "",
                },
                phoneNumber: {
                  value: value?.companyProfile?.phoneNumber?.value || "",
                },
                source: {
                  value: "",
                },
                state: {
                  value: "",
                },
                contact_id: {
                  value: value?.companyProfile?.contact_id?.value || "",
                },
              },
              visitCount: 0,
              visitTimestampHistory: [],
            };
          }
        });
        let addQuesData = [];
        for (let question of companyProfileAddQuestions) {
          let dataQues = {
            response: question.response ? "Y" : "N",
            name: question.name,
            ques: question.ques,
          };
          addQuesData.push(dataQues);
        }

        let riskProfileRequestBody = this.createRiskProfileBody(
          childrenLoc,
          peoDetails,
          companyProfile
        );
        let riskProfileResponse = {};

        await new Promise(async (resolve, reject) => {
          try {
            const riskProfileDataID = await getRiskProfileDataId(
              riskProfileRequestBody
            );

            let id = riskProfileDataID.data.id;

            const fetchRiskProfile = async (id) => {
              fetchRiskProfileData(id).then(async (res) => {
                if (res.data === "processing") {
                  setTimeout(() => fetchRiskProfile(id), 5000);
                } else if (res.data === "error") {
                  resolve();
                } else {
                  let urlResponse = await axios.get(res.data.url);
                  riskProfileResponse = urlResponse.data;

                  // console.log(riskProfileResponse, res.data);
                  resolve();

                  // $("#loader").css("display", "none");
                }
              });
            };
            await fetchRiskProfile(id);
          } catch (error) {
            console.log(error);
            // $("#loader").css("display", "none");
            resolve();
          }
        });

        _currProspect = {
          childrenLoc: childrenLoc || {},
          companyProfile: companyProfile || {},
          emodStatesData: emodStatesData || {},
          companyProfileAddQuestions: addQuesData || {},
          riskProfile: riskProfileResponse,
          peoDetails,
          carrierList,
          uwQues: "",
          email_specific: _email_specific || {},
        };

        let etQuoteDetails = {
          domain: companyId,
          childrenLoc: childrenLoc || {},
          companyProfile: companyProfile || {},
          emodStatesData: emodStatesData || {},
          status: "company_profile",
          uploadTimestamp: Math.floor(Date.now()).toString(),
          // modifiedBy: emailID || "",
          riskProfile: riskProfileResponse || {},
          peoDetails: peoDetails || {},
          carrierList: carrierList || {},
          secondary_hash_key: "true",
          email_specific: _email_specific || {},
        };

        if (uw_flow) {
          try {
            let _uwQues = currProspect.uwQues;
            _currProspect.uwQues = _uwQues;
          } catch (error) {}
        } else {
          userTableData(etQuoteDetails, "put")
            .then((res2) => {
              setUserData(etQuoteDetails);
              setUserStatusData({});
            })
            .catch((err) => {
              console.log("Error:", err);
            });
        }

        let errorInClassCodeValidation = false,
          validationCcField = "";

        validateClassCodesAndDescription(childrenLoc)
          .then((res3) => {
            let responseData = res3.data;
            if (responseData.hasError) errorInClassCodeValidation = true;
            childrenLoc = responseData.childrenLoc;
            validationCcField = responseData.field;
          })
          .catch((err) => {
            console.log("Error:", err);
          });

        if (!errorInClassCodeValidation) {
          this.setState({ continueErr: "" });
          setCurrProspect(_currProspect);
          stopLoader();
          setContextData("getQuoteClicked", true);
          setFormStage("two");
        } else {
          this.setState({
            continueErr: "Invalid ClassCode/Description",
            childrenLoc,
          });
          stopLoader();
          document
            .getElementById(validationCcField)
            .scrollIntoView({ behavior: "smooth" });
        }
        stopLoader();
      }
    } catch (error) {
      console.log(error);
      stopLoader();
    }
  };
  getDomainFromEmail = (user) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let response;
        let generalDomainNames = [
          "gmail",
          "yahoo",
          "aol",
          "mac",
          "cox",
          "frontier",
          "msn",
          "gammana",
          "hotmail",
          "pacbell",
          "att",
          "sbcglobal",
          "windstream",
          "verizon",
          "comcast",
          "ampcocontracting",
        ];
        let tempBusinessName = user.split("@")[1].split(".");
        tempBusinessName.pop();
        let business_name = tempBusinessName.join(".");
        if (generalDomainNames.includes(business_name)) {
          response = user.split("@")[0];
        } else {
          response = business_name;
        }

        let domainResp = await getDomain(user);
        if (domainResp.data && domainResp.data.domain) {
          response = domainResp.data.domain;
        }

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  fetchAndFillDetails = () => {
    try {
      $("#loader").css("display", "block");
      let user = this.state.companyProfile.emailId.value;

      getUserDataStatus(user, "quote")
        .then(async (response) => {
          if (response.data) {
            let carrierBasedData = response.data.carrierBasedData;
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];
                let childrenLoc = {};

                let uwQues = lineObj.uw_ques;

                let companyProfile = {
                  companyName: { value: lineObj.company_name },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  fein: { value: lineObj.fein },
                  emailId: { value: lineObj.user_email_id },
                  phoneNumber: { value: lineObj.phone_number },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  effectiveDate: {
                    value: lineObj.effective_date,
                  },
                  healthQues: {
                    value:
                      lineObj.healthQues === true ||
                      lineObj.healthQues === false
                        ? lineObj.healthQues
                        : true,
                  },
                };

                let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
                let discountData = lineObj.discountData;

                if (discountData && discountData.value) {
                  if (discountData.type === "percent") {
                    let valueToSub = numeral(adminFeeTemp)
                      .multiply(discountData.value)
                      .divide(100)
                      .value();
                    adminFeeTemp = numeral(adminFeeTemp)
                      .subtract(valueToSub)
                      .value();
                  } else {
                    adminFeeTemp = discountData.value;
                  }
                }

                let quoteFactors = {
                  ficaCharge: lineObj.carrier_location_data.fica,
                  futaCharge: lineObj.carrier_location_data.futa,
                  sutaCharge: lineObj.carrier_location_data.suta,
                  teap: lineObj.carrier_location_data
                    .total_estimated_annual_premium,
                  total: lineObj.carrier_location_data.total,
                  adminFee: adminFeeTemp,
                  payroll: 0,
                };

                let emodStatesData = lineObj.emodStatesData;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                for (let prospectAddressesIndx in lineObj.prospect_addresses) {
                  let prospectAddressesObj =
                    lineObj.prospect_addresses[prospectAddressesIndx];

                  let rand = Math.random();
                  childrenLoc[prospectAddressesIndx] = {
                    cityName: {},
                    classCodesInfo: {},
                    key: rand,
                    number: prospectAddressesObj.addressId,
                    state: { value: prospectAddressesObj.stateCode },
                    zipcode: {},
                  };
                  for (let classCodesIndx in prospectAddressesObj.classCodes) {
                    var formatter = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
                    let classCodesObj =
                      prospectAddressesObj.classCodes[classCodesIndx];
                    childrenLoc[prospectAddressesIndx]["classCodesInfo"][
                      classCodesIndx
                    ] = {
                      classCodeDescription: {
                        value: `${classCodesObj.code}: ${classCodesObj.description}`,
                      },
                      ft: { value: classCodesObj.ft },
                      pt: { value: classCodesObj.pt },
                      payroll: {
                        value: formatter.format(classCodesObj.payroll),
                      },
                    };
                    quoteFactors.payroll += numeral(
                      classCodesObj.payroll
                    ).value();
                  }
                }
                currProspect = {
                  childrenLoc,
                  companyProfile,
                  uwQues,
                  emodStatesData,
                };
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem(
                  "childrenLoc",
                  JSON.stringify(childrenLoc)
                );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                this.props.setFormStage("three");
                sessionStorage.setItem(
                  "quoteFactors",
                  JSON.stringify(quoteFasuctors)
                );
              }
            }
            $("#loader").css("display", "none");
            // //window.location.reload();
          } else {
            let domain;
            try {
              domain = await this.getDomainFromEmail(user);
            } catch (error) {
              console.log("error in getting domain", error);
            }

            sessionStorage.setItem("domain", domain);
            getDomainInputData(domain).then((response) => {
              if (response.status === 200 && response.data.email_specific) {
                let {
                  companyProfile,
                  childrenLoc,
                  emodStatesData,
                  indicationCost,
                  email_specific,
                } = response.data;

                if (email_specific) {
                  if (user in email_specific) {
                    companyProfile = email_specific[user].companyProfile;
                  } else {
                    companyProfile.emailId.value = user;
                  }
                }

                companyProfile.effectiveDate.value = moment(
                  companyProfile.effectiveDate.value
                );

                for (let emodKey in emodStatesData) {
                  if (emodStatesData[emodKey].date1.value)
                    emodStatesData[emodKey].date1.value = moment(
                      emodStatesData[emodKey].date1.value
                    );
                  if (emodStatesData[emodKey].date2.value)
                    emodStatesData[emodKey].date2.value = moment(
                      emodStatesData[emodKey].date2.value
                    );
                }

                if (!("healthQues" in companyProfile)) {
                  companyProfile.healthQues = { value: true };
                }

                let currProspect = {
                  childrenLoc,
                  companyProfile,
                  emodStatesData,
                };

                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                this.setState({
                  childrenLoc,
                  companyProfile,
                  emodStatesData,
                });
              }
              $("#loader").css("display", "none");
            });
          }
          sessionStorage.setItem("otpVerified", "true");
        })
        .catch((error) => {
          console.log("error in data retrieval");
          $("#loader").css("display", "none");
        });
    } catch (error) {
      console.log("error in GET button click");
    }
  };

  updateHealthDropDown(event) {
    let { companyProfile } = this.state;
    companyProfile.healthQues.value =
      event.target.value === "true" ? true : false;
    this.setState({ companyProfile });
  }

  onrmvChild = (addressId) => {
    let { childrenLoc, emodStatesData, state_class_code } = this.state;

    if (!childrenLoc[addressId].state || !childrenLoc[addressId].state.value) {
      delete childrenLoc[addressId];
      this.setState({ childrenLoc });
      return;
    }

    let stateDeleted = childrenLoc[addressId].state.value;
    let stateDoesNotExistAgain = true;
    let firstToBeDisabledIndex;

    //cc_delete
    for (let classCodeIndex in childrenLoc[addressId].classCodesInfo) {
      let locationIndex = addressId;
      let currState = childrenLoc[locationIndex].state.value || "";
      if (
        currState.toLowerCase() in state_class_code &&
        state_class_code[currState.toLowerCase()].val
      ) {
        let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
          classCodeIndex
        ]["classCodeDescription"]
          ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value
          : undefined;
        if (class_code_desc) {
          let class_code = class_code_desc.split(":")[0].trim();
          if (
            state_class_code[currState.toLowerCase()].cc_dsc &&
            class_code in state_class_code[currState.toLowerCase()].cc_dsc
          ) {
            let locToUpdate, ccToUpdate;
            for (let locIndx in childrenLoc) {
              if (childrenLoc[locIndx].state.value === currState) {
                let ccInfo = childrenLoc[locIndx].classCodesInfo;
                for (let ccIndx in ccInfo) {
                  let cc_desc;
                  try {
                    cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                  } catch (e) {}
                  if (cc_desc) {
                    let cc = cc_desc.split(":")[0].trim();
                    if (Number(cc) === Number(class_code)) {
                      childrenLoc[locIndx].classCodesInfo[ccIndx]["pricing"] = {
                        value:
                          state_class_code[currState.toLowerCase()].cc_dsc[
                            class_code
                          ],
                        disabled: true,
                      };
                      if (
                        !locToUpdate &&
                        !ccToUpdate &&
                        Number(locIndx) !== Number(locationIndex)
                      ) {
                        locToUpdate = locIndx;
                        ccToUpdate = ccIndx;
                      }
                    }
                  }
                }
              }
            }
            if (locToUpdate && ccToUpdate) {
              childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                "pricing"
              ].disabled = false;
              if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
                state_class_code[currState.toLowerCase()].cc_dsc = {};
              }
              try {
                state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                  childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                    "pricing"
                  ].value;
              } catch (error) {}
            } else {
              delete state_class_code[currState.toLowerCase()].cc_dsc[
                class_code
              ];
            }
          }
        }
      }
    }
    //cc_delete

    delete childrenLoc[addressId];

    let stateList = [];
    for (let addressBlockNo in childrenLoc) {
      if (childrenLoc[addressBlockNo].state.value) {
        if (childrenLoc[addressBlockNo].state.value === stateDeleted) {
          childrenLoc[addressBlockNo].suta.disabled = true;
          if (!firstToBeDisabledIndex) firstToBeDisabledIndex = addressBlockNo;
          stateDoesNotExistAgain = false;
        }
        stateList.push(childrenLoc[addressBlockNo].state.value.toLowerCase());
      }
    }

    if (firstToBeDisabledIndex) {
      childrenLoc[firstToBeDisabledIndex].suta.disabled = false;
    }

    if (stateList.length > 0) {
      let statesData = {
        ncciStates: [],
        actingNcciStates: [],
        indBureauStates: [],
      };

      // let carrierPeoProgramList = carrierList.carrierPeoProgramList;

      let programList = [];

      for (let carrierPeoProgramObj of carrierPeoProgramList) {
        programList.push({ display_name: carrierPeoProgramObj.program });
      }
      raterMcpStateData(stateList, programList)
        .then((response) => {
          if (response?.data?.length > 0) {
            for (let state of response.data) {
              if (!(state in emodStatesData)) {
                emodStatesData[state] = {
                  rate1: {
                    value: "",
                  },
                  response: false,
                  displayMore: false,
                  date1: { value: moment() },
                  date2: { value: moment() },
                };
              }
            }

            for (let state in emodStatesData) {
              if (!stateList.includes(state)) {
                delete emodStatesData[state];
              }
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.setState({
            childrenLoc,
            emodStatesData,
            state_class_code,
          });
        });
    } else {
      emodStatesData = {};
      this.setState({ childrenLoc, emodStatesData, state_class_code });
    }
  };

  setEmailSpecificData = (email_specificData) => {
    this.setState({ email_specific: email_specificData });
  };
  // add the new contact details
  showAddtionalContactDetailsHandler = () => {
    let { email_specific } = this.state;
    const keys = Object.keys(email_specific);
    let index;

    if (keys.length === 0) {
      index = 0; // If there are no keys in the classCodesInfo object, set the index to 0
    } else {
      index = Math.max(...keys.map((key) => parseInt(key))) + 1; // Calculate the next available index by finding the highest key value and adding 1 to it
    }
    this.setState((prevState) => ({
      email_specific: {
        ...prevState.email_specific,
        [index]: {
          companyProfile: {
            company_name: {},
            contact_name: {},
            emailId: {},
            job_title: {},
            phoneNumber: {},
            source: {},
            state: {},
            newCreateContact: { value: true },
          },
        },
      },
    }));
  };

  // Remove the contact information.
  deleteContactDetailsHandler = (index) => {
    let { email_specific } = this.state;
    delete email_specific[index];
    this.setState({ email_specific });
  };

  // update the contact
  updateContactDetails = (event, index) => {
    let { email_specific } = this.state;
    let { value, name } = event.target;
    // checking if user is editing the existing contact or not. if isedit is true then we updated the existing contact
    const isContactEdited = email_specific?.[index]?.companyProfile
      ?.newCreateContact?.value
      ? false
      : true;

    // Update the contact editing status
    email_specific[index].companyProfile.isContactEdited = {
      value: isContactEdited,
    };
    this.setState((prevState) => ({
      ...prevState,
      email_specific: {
        ...prevState.email_specific,
        [index]: {
          ...prevState.email_specific[index],
          companyProfile: {
            ...prevState.email_specific[index].companyProfile,
            [name]: {
              ...prevState.email_specific[index].companyProfile[name],
              ["value"]: value,
            },
          },
        },
      },
    }));
  };

  saveContactHandler = async () => {
    try {
      let { email_specific } = this.state;
      this.setState({ contact_loader: true });
      let [contactDetialsError] = this.checkContactDetailsError();
      if (contactDetialsError) {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let companyId = urlParams.get("user") || sessionStorage.getItem("user");
        // Save the Contact Details in Hubspot.
        let contactdetialsSaveResponse = await sendContactDetailsToHubspot(
          email_specific,
          companyId
        );
        this.setState({
          contact_loader: false,
          email_specific:
            contactdetialsSaveResponse.data.response.email_specific,
        });
        // end of save contact details to hubspot
      } else {
        throw new Error("Validation failed");
      }
    } catch (error) {
      console.log(error);
      this.setState({ contact_loader: false });
    }
  };

  render() {
    let {
      companyProfile,
      peoDetails,
      carrierList,
      childrenLoc,
      emodStatesData,
      userDetails,
      sameAsPrimary,
      continueErr,
      email_specific,
      email_specificError,
      contact_loader,
    } = this.state;
    let {
      hideButtons,
      setUserData,
      setUserStatusData,
      sessionData,
      setFormStage,
      setCurrProspect,
      currProspect,
      stopLoader,
      startLoader,
      setContextData,
    } = this.props;

    let expstartdate;

    // console.log("companyProfile: ", companyProfile);

    try {
      expstartdate = companyProfile.expectedStartDate.value;
    } catch (error) {}

    let _carrierList = [];

    try {
      for (let data of carrierPeoProgramList) {
        if (data.devRemoved && process.env.REACT_APP_MODE === "dev") continue;
        if (data.prodRemoved && process.env.REACT_APP_MODE === "prod") continue;
        if (data.disableCarrier) continue;
        if (data.range) {
          let expectedStartDate = moment(expstartdate, "YYYY-MM-DD");
          let includeCarrier = false;
          for (let range of data.range) {
            if (!range.to && range.from) {
              const fromDate = moment(range.from, "YYYY-MM-DD");

              if (fromDate.isSameOrBefore(expectedStartDate)) {
                includeCarrier = true;
                break;
              }
            }
            if (!range.from && range.to) {
              const toDate = moment(range.to, "YYYY-MM-DD");
              if (toDate.isAfter(expectedStartDate)) {
                includeCarrier = true;
                break;
              }
            }
            if (range.to && range.from) {
              const fromDate = moment(range.from, "YYYY-MM-DD");
              const toDate = moment(range.to, "YYYY-MM-DD");

              if (
                expectedStartDate.isSameOrAfter(fromDate) &&
                expectedStartDate.isBefore(toDate)
              ) {
                includeCarrier = true;
                break;
              }
            }
          }
          if (!includeCarrier) continue;
        }

        if (data.peo.toLowerCase() === peoDetails.selectedPeo) {
          // if (data.peo.toLowerCase().split(" ").join("") === peoDetails.selectedPeo) {
          _carrierList.push({
            label: data.actualCarrierName,
            value: data.carrier,
          });
        } else if (peoDetails.selectedPeo === "demo" && data.peo === "gms") {
          _carrierList.push({
            label: data.carrier?.toUpperCase(),
            value: data.carrier,
          });
        }
      }
    } catch (error) {}

    _carrierList.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });

    if (_carrierList.length > 0)
      _carrierList.unshift({
        label: "Select All",
        value: "select_all",
      });

    return (
      <div id="compPro" className="container-fluid active per98">
        <NewProgress />
        <div className="parse-acord mb-5">
          <div className={`${styles.pink_header} m-0 p-0 mb-3`}>
            <div className={styles.font_family_montserrat_bold}>
              Parse Acord
            </div>
          </div>
          <UploadAcordFile updateFormFields={this.updateFormFields} />
        </div>
        <div id="compProfile">
          <MinimizeComponent title={"Company Profile"}>
            <CompanyProfile
              companyProfile={companyProfile}
              userDetails={userDetails}
              peoDetails={peoDetails}
              carrierList={carrierList}
              childrenLoc={childrenLoc}
              setCompanyProfile={this.setCompanyProfile}
              setPeoDetails={this.setPeoDetails}
              setCarrierList={this.setCarrierList}
              _carrierList={_carrierList}
              emodStatesData={emodStatesData}
              refreshEmodDetails={this.refreshEmodDetails}
              sessionData={sessionData}
            />
          </MinimizeComponent>

          <MinimizeComponent title={"Contact Details"}>
            {Object.keys(email_specific).length > 0 && (
              <ContactDetails
                contactDetailsData={email_specific}
                deleteContactDetailsHandler={this.deleteContactDetailsHandler}
                updateContactDetails={this.updateContactDetails}
                email_specificError={email_specificError}
              />
            )}
            <div className={`mt-2 ml-3 mb-3 ${styles.contactSaveButton}`}>
              <button
                className="btnInvisible addLoc transpButton p-0 btn"
                onClick={this.showAddtionalContactDetailsHandler}
              >
                <img src={plusSymbol} alt="Add" />
                <span>&nbsp;&nbsp;</span>
                <b>Add Contact Details</b>
              </button>
              {Object.keys(email_specific).length > 0 ? (
                <button
                  className="btn btn-warning"
                  onClick={() => this.saveContactHandler()}
                >
                  {" "}
                  Save Contact Details
                </button>
              ) : (
                ""
              )}
              {contact_loader ? <SmallLoader /> : ""}
            </div>
          </MinimizeComponent>

          <Form>
            <div className="formTitle">
              <b className="font-family-montserrat-bold">
                {"Please verify/update below details"}
              </b>
            </div>

            <MinimizeComponent title={"Locations & Payroll"}>
              <LocationAndPayroll
                effectiveDate={companyProfile.effectiveDate}
                childrenLoc={childrenLoc}
                emodStatesData={emodStatesData}
                peoDetails={peoDetails}
                carrierList={carrierList}
                setChildrenLoc={this.setChildrenLoc}
                refreshEmodDetails={this.refreshEmodDetails}
              />
            </MinimizeComponent>

            <Emod
              emodStatesData={emodStatesData}
              setEmodStatesData={this.setEmodStatesData}
            />
          </Form>
          <div className="row pt-5">
            <div className="col-12 d-flex justify-content-center">
              <button
                type="button"
                onClick={() => {
                  if (!hideButtons)
                    this.submitCompPro(
                      setUserData,
                      setUserStatusData,
                      sessionData,
                      setFormStage,
                      setCurrProspect,
                      currProspect,
                      stopLoader,
                      startLoader,
                      setContextData
                    );
                }}
                id="profileSaveButton"
                className={`btn mt-0 btn-warning btn-lg btn-warning-shadow ${styles.btnSubmits}`}
                disabled={hideButtons}
              >
                Continue
              </button>
            </div>
            <div className="col-12 d-flex justify-content-center m-2">
              {continueErr && <p className="errMsg">{continueErr}</p>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function FormCompProWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => <FormCompPro {...props} {...context} />}
    </LibertateContext.Consumer>
  );
}
