import axios from "axios";
import moment from "moment";
import { userTableData } from "../api/commonApi";
import {
  getCarrierElig,
  getQuotesDataId,
  getQuotesData,
} from "../api/formunderQuesApi";

import { postUsersData } from "../api/formunderQuesApi";

const quote_dropped = require("./quote_dropped.json");

//ca, keyrisk => Starnet and midwest

const uuidv4 = require("uuid/v4");
const numeral = require("numeral");
require("bootstrap");
const { carrierPeoProgramList, programMapping } = require("./carrierList.json");

export let checkQuestionsValidity = (quesList) => {
  let validQuestions = true;
  for (let question of [...quesList]) {
    if (question.response !== true && question.response !== false) {
      validQuestions = false;
      break;
    }
  }

  return validQuestions;
};

const getAvailableCarriers = (carrierEligibilityMap) => {
  let response = [];
  try {
    for (let carrier in carrierEligibilityMap) {
      response.push(carrier);
    }
  } catch (error) {
    response = [];
  }

  return response;
};

export async function submitQues(
  setFormStage,
  quesList,
  updatePopUpData,
  isLoggedIn,
  startLoader,
  stopLoader,
  setCurrProspect,
  acordUwQues,
  currProspect,
  isUwFlow,
  submissionDate,
  currentUser,
  visitTimestamp,
  uuid,
  setContextData
) {
  startLoader();
  try {
    let currProspectDetails = JSON.parse(JSON.stringify(currProspect));

    let user_id = currentUser;

    if (!user_id) {
      stopLoader();
      return;
    }

    let uwQuesData = [];
    let carrierUserData = [];
    let addQuestionResponses = [];
    let addQuestionIndexMap = {
      q25: 0,
      q26: 1,
      q27: 2,
      q28: 3,
    };

    // for (let question of quesList) {
    //   let dataQues = {
    //     response: question.response ? "Y" : "N",
    //     name: question.name,
    //     ques: question.ques,
    //     remarks: question.remarks,
    //   };
    //   if (question.name in addQuestionIndexMap) {
    //     let responseIndex = addQuestionIndexMap[question.name];
    //     addQuestionResponses[responseIndex] = question.response ? 1 : 0;
    //   }
    //   uwQuesData.push(dataQues);
    // }

    currProspectDetails.uwQues = quesList;

    setCurrProspect(currProspectDetails);

    const {
      childrenLoc,
      companyProfile,
      emodStatesData,
      uwQues,
      peoDetails,
      carrierList,
      riskProfile,
    } = currProspectDetails;

    if (childrenLoc && companyProfile && emodStatesData) {
      if (!companyProfile?.expectedExpiryDate?.value) {
        companyProfile.expectedExpiryDate = {
          value: moment(companyProfile.expectedStartDate.value).add(1, "year"),
        };
      }

      let dataBody = {
        common: {
          el_limits: "1000-1000-1000",
          date_from: moment(companyProfile.expectedStartDate.value).format(
            "YYYY-MM-DD"
          ),
          date_to: moment(companyProfile.expectedExpiryDate.value).format(
            "YYYY-MM-DD"
          ),
          emod: [],
          questions: addQuestionResponses || [],
          sr_carriers: {},
        },
        offices: [],
      };

      let emodForMail;

      let emodStatesDataCopy = JSON.parse(JSON.stringify(emodStatesData));

      // console.log("emodStatesDataCopy before: ", emodStatesDataCopy);

      if (emodStatesDataCopy["ncci"]) {
        let ncciEmodValue1 = emodStatesDataCopy["ncci"]?.rate1?.value || "";
        let ncciEmodValue2 = emodStatesDataCopy["ncci"]?.rate2?.value || "";
        let date1 = emodStatesDataCopy["ncci"]?.date1?.value;
        let date2 = emodStatesDataCopy["ncci"]?.date2?.value;

        for (let ncci_state of emodStatesDataCopy["ncci"]?.stateList || []) {
          if (!(ncci_state in emodStatesDataCopy)) {
            emodStatesDataCopy[ncci_state] = {
              rate1: {
                value: ncciEmodValue1,
              },
              rate2: {
                value: ncciEmodValue2,
              },
              response: ncciEmodValue1 ? true : false,
              displayMore: emodStatesDataCopy["ncci"]?.displayMore,
              date1: { value: date1 || moment() },
              date2: { value: date2 || moment() },
            };
          }
        }
        delete emodStatesDataCopy["ncci"];
      }

      // console.log("emodStatesDataCopy after: ", emodStatesDataCopy);
      for (let state in emodStatesDataCopy) {
        let emodObj = {};
        if (emodStatesDataCopy[state].response) {
          emodObj = {
            value_1: emodStatesDataCopy[state].rate1.value,
            effective_date_1: moment(
              emodStatesDataCopy[state].date1.value
            ).format("YYYY-MM-DD"),
          };

          if (emodForMail == null && emodStatesDataCopy[state].rate1.value)
            emodForMail = emodStatesDataCopy[state].rate1.value;

          emodObj.stateList =
            state === "ncci" ? emodStatesDataCopy[state].stateList : [state];
          if (emodStatesDataCopy[state].displayMore) {
            emodObj.value_2 = emodStatesDataCopy[state]?.rate2?.value || "";
            emodObj.effective_date_2 = moment(
              emodStatesDataCopy[state].date2.value
            ).format("YYYY-MM-DD");
          }

          dataBody.common.emod.push(emodObj);
        }
      }

      let carrierEligBody = {};
      let stateLocMap = {};
      let payrollMap = {};

      //proRate payroll
      let total_days = moment(
        moment(companyProfile.expectedStartDate.value).add(1, "year")
      ).diff(moment(companyProfile.expectedStartDate.value), "days");

      let dates_diff_in_days = moment(
        companyProfile.expectedExpiryDate.value
      ).diff(moment(companyProfile.expectedStartDate.value), "days");

      let prorateValue = numeral(dates_diff_in_days).divide(total_days).value();

      if (Number(prorateValue) > 1) {
        prorateValue = 1;
      } else {
        prorateValue = Math.round(Number(prorateValue) * 1000) / 1000;
      }

      dataBody.common.prorateValue = Number(prorateValue);

      let has_ca_state = false;

      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
          childrenLoc[addressBlockNo].state.value
        }_${
          (childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
        }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          suta: "",
          employees: [],
          carriers: [],
        };

        if (childrenLoc[addressBlockNo].state.value.toLowerCase() === "ca")
          has_ca_state = true;

        let key = `${name}_${addressBlockNo}`;
        if (!(key in carrierEligBody)) {
          let state = key.split("_")[3].toLowerCase();
          if (state in stateLocMap) {
            key = stateLocMap[state];
          } else {
            carrierEligBody[key] = [];
            stateLocMap[state] = key;
          }
        }

        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray =
            childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();

          carrierEligBody[key].push({
            code,
            description: desc,
            state,
          });

          if (!payrollMap[state]) {
            payrollMap[state] = {};
          }

          payrollMap[state][code] = payroll;

          let proRatedPayroll = numeral(payroll)
            .multiply(dates_diff_in_days)
            .divide(total_days)
            .value();

          childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].proRatedPayroll = {
            value: proRatedPayroll.toString(),
          };

          let _payroll;

          try {
            if (payroll?.includes("$") && payroll?.split("$")?.[1]) {
              _payroll = payroll.split("$")[1].split(",").join("");
            } else {
              _payroll = payroll.split(",").join("");
            }
          } catch (error) {}

          let classCodeObj = {
            code,
            payroll: Number(proRatedPayroll).toString(),
            original_payroll: _payroll,
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
            pricing:
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                .pricing &&
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pricing
                .value
                ? childrenLoc[addressBlockNo].classCodesInfo[
                    addressChildNo
                  ].pricing.value.split("%")[0]
                : "",
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      let quoteFactors = {};

      getCarrierElig(carrierEligBody)
        .then(async (res) => {
          let data = res.data.eligibility,
            nonEligibleMap;
          var eligData = res.data.eligibility;
          let industries = res.data.industries;
          let details = res.data.details;

          let eligibilityPriorityMap = {
            no: 3,
            uw: 2,
            yes: 1,
          };
          let carrierEligibilityMap = {};

          for (let location in data) {
            for (let carrier in data[location]) {
              if (data[location][carrier]) {
                if (!carrierEligibilityMap[carrier]) {
                  carrierEligibilityMap[carrier] =
                    data[location][carrier].value;
                } else if (
                  eligibilityPriorityMap[data[location][carrier].value] >
                  eligibilityPriorityMap[carrierEligibilityMap[carrier]]
                ) {
                  carrierEligibilityMap[carrier] =
                    data[location][carrier].value;
                }
              }
            }
          }
          let availableCarrierList = getAvailableCarriers(
            carrierEligibilityMap
          );
          let userSelectedCarriers = [];
          let carriersListToSend = [];

          for (let obj of carrierList.value) {
            userSelectedCarriers.push(obj.value);
          }

          for (let carrier of carrierPeoProgramList) {
            if (
              userSelectedCarriers.includes(carrier.carrier) &&
              availableCarrierList.includes(carrier.carrier) &&
              (peoDetails.selectedPeo === carrier.peo ||
                (peoDetails.selectedPeo === "demo" && carrier.peo === "gms"))
            )
              carriersListToSend.push(carrier);
          }

          let additional_carr_list = [];
          let have_to_add_additional_carriers_in_ca = false;
          if (has_ca_state && userSelectedCarriers.includes("carrier_r")) {
            have_to_add_additional_carriers_in_ca = true;
            if (!userSelectedCarriers.includes("carrier_ac")) {
              for (let carr_obj of carrierPeoProgramList) {
                if (
                  carr_obj.carrier === "carrier_ac" &&
                  (peoDetails.selectedPeo === carr_obj.peo ||
                    (peoDetails.selectedPeo === "demo" &&
                      carr_obj.peo === "gms"))
                )
                  additional_carr_list.push(carr_obj);
              }
            }
            if (!userSelectedCarriers.includes("carrier_ab")) {
              for (let carr_obj of carrierPeoProgramList) {
                if (
                  carr_obj.carrier === "carrier_ab" &&
                  (peoDetails.selectedPeo === carr_obj.peo ||
                    (peoDetails.selectedPeo === "demo" &&
                      carr_obj.peo === "gms"))
                )
                  additional_carr_list.push(carr_obj);
              }
            }
          }

          if (availableCarrierList.length > 0) {
            for (let office of dataBody.offices) {
              if (
                office.state === "ca" &&
                have_to_add_additional_carriers_in_ca
              ) {
                office.carriers.push(
                  ...carriersListToSend,
                  ...additional_carr_list
                );
              } else {
                office.carriers.push(...carriersListToSend);
              }
            }

            const resID = await getQuotesDataId(dataBody);

            let id = resID.data.id;
            let fetchData = (id) => {
              let dropped = true;
              getQuotesData(id)
                .then(async (res) => {
                  if (res.data === "processing") {
                    const myTimeout = setTimeout(() => fetchData(id), 5000);
                  } else if (res.data === "error") {
                    stopLoader();
                    this.setState({
                      errorMessage: "Oops!! Timed Out. Please try again.",
                    });
                  } else {
                    try {
                      let urlData = res.data;
                      let quoteDynamoDbRuns;

                      let urlResponse = await axios.get(urlData.url);
                      let data = urlResponse.data;

                      if (data) {
                        for (let location in data) {
                          let locDropped = true;
                          for (let peo in data[location]) {
                            let peoDropped = true;
                            for (let carrier in data[location][peo]) {
                              if (data[location][peo][carrier] !== "Dropped") {
                                peoDropped = false;
                                dropped = false;
                                locDropped = false;
                                if (!(peo in quoteFactors)) {
                                  quoteFactors[peo] = {};
                                }
                                if (!(carrier in quoteFactors[peo])) {
                                  quoteFactors[peo][carrier] = {
                                    teap: 0,
                                    total: 0,
                                    futaCharge: 0,
                                    ficaCharge: 0,
                                    sutaCharge: 0,
                                    payroll: 0,
                                    adminFee: 0,
                                  };
                                }
                                quoteFactors[peo][carrier].adminFee =
                                  data[location][peo][carrier].admin_fee;
                                quoteFactors[peo][carrier].teap += Number(
                                  data[location][peo][carrier]
                                    .total_estimated_annual_premium
                                );
                                quoteFactors[peo][carrier].total += Number(
                                  data[location][peo][carrier].total
                                );
                                quoteFactors[peo][carrier].futaCharge = Number(
                                  data[location][peo][carrier].futa
                                );
                                quoteFactors[peo][carrier].ficaCharge = Number(
                                  data[location][peo][carrier].fica
                                );
                                quoteFactors[peo][carrier].sutaCharge = Number(
                                  data[location][peo][carrier].suta
                                );
                              } else {
                                let manual_rate_values = {};
                                let currState = location
                                  .split("_")
                                  .reverse()[1]
                                  .toLowerCase();
                                let officesObj = dataBody.offices;
                                let employees = [];
                                let program;

                                for (let _program in programMapping) {
                                  let carrierPeoProgramObj =
                                    programMapping[_program];

                                  if (
                                    carrierPeoProgramObj.peo === peo &&
                                    carrierPeoProgramObj.carrier === carrier
                                  ) {
                                    program = _program;
                                    break;
                                  }
                                }

                                for (let office of officesObj) {
                                  if (office.state === currState)
                                    employees = office.employees;
                                }

                                for (let employee of employees) {
                                  manual_rate_values[employee.code] = 0;
                                }

                                //delete carrier from data
                                data[location][peo][carrier] = JSON.parse(
                                  JSON.stringify(quote_dropped)
                                );

                                data[location][peo][carrier].peo = peo;
                                data[location][peo][carrier].program = program;
                                data[location][peo][carrier].carriers =
                                  carriersListToSend;
                                data[location][peo][carrier].el_limits =
                                  dataBody.common.el_limits;
                                data[location][peo][carrier].date_from =
                                  dataBody.common.date_from;
                                data[location][peo][carrier].date_to =
                                  dataBody.common.date_to;
                                data[location][peo][carrier].carrier = carrier;
                                data[location][peo][carrier].carrier_email =
                                  carrier + "@gmail.com";
                                data[location][peo][carrier].employees =
                                  employees;
                                data[location][peo][
                                  carrier
                                ].original_employees = employees;
                                data[location][peo][
                                  carrier
                                ].manual_rate_values = manual_rate_values;
                                data[location][peo][carrier].net_rate_values =
                                  manual_rate_values;
                                data[location][peo][
                                  carrier
                                ].manual_premium_values = manual_rate_values;
                                data[location][peo][carrier].state = currState;
                              }
                            }
                          }
                        }

                        if (true) {
                          let prospectAddresses = {};

                          // create the prospect address object
                          for (let stateIndex in childrenLoc) {
                            prospectAddresses[stateIndex] = {
                              stateCode: childrenLoc[stateIndex].state.value,
                              addressId: childrenLoc[stateIndex].number,
                              classCodes: [],
                            };
                            for (let classCodeIndex in childrenLoc[stateIndex]
                              .classCodesInfo) {
                              let classCodeDescArray =
                                childrenLoc[stateIndex].classCodesInfo[
                                  classCodeIndex
                                ].classCodeDescription.value.split(":");

                              let code = classCodeDescArray.shift();
                              let desc = classCodeDescArray.join(":").trim();

                              prospectAddresses[stateIndex].classCodes.push({
                                code,
                                payroll: numeral(
                                  childrenLoc[stateIndex].classCodesInfo[
                                    classCodeIndex
                                  ].payroll.value
                                )
                                  .value()
                                  .toString(),
                                pt: childrenLoc[stateIndex].classCodesInfo[
                                  classCodeIndex
                                ].pt.value.trim(),
                                ft: childrenLoc[stateIndex].classCodesInfo[
                                  classCodeIndex
                                ].ft.value.trim(),
                                description: desc,
                              });
                            }
                          }

                          let currentDate = Math.floor(Date.now()).toString();
                          if (!uuid) {
                            uuid = uuidv4();
                          }

                          let sortKeyList = [];
                          let address = [];
                          let quoteData;
                          let class_code_object = {};

                          for (let location in eligData) {
                            for (let carrier in eligData[location]) {
                              for (let ele of carrierPeoProgramList) {
                                let place = location
                                  .split("_")
                                  .slice(0, 5)
                                  .join("_");
                                if (
                                  ele.carrier === carrier &&
                                  data[place] &&
                                  ele.peo in data[place] &&
                                  data[place][ele.peo][carrier] &&
                                  data[place][ele.peo][carrier] !== "Dropped"
                                ) {
                                  if (!(place in class_code_object))
                                    class_code_object[place] = {};
                                  if (!(ele.peo in class_code_object[place]))
                                    class_code_object[place][ele.peo] = {};

                                  class_code_object[place][ele.peo][carrier] =
                                    eligData[location][
                                      carrier
                                    ].classCodeEligibility;
                                }
                              }
                            }
                          }

                          let carrierBasedData = {},
                            stateCarrierData = {};

                          for (let place in data) {
                            address.push(place);
                            for (let peo in data[place]) {
                              let tempData = {
                                producerName:
                                  currProspectDetails?.["companyProfile"]?.[
                                    "producer"
                                  ]?.["value"] || "Paul Hughes",
                                producerEmail: isLoggedIn.email || " ",
                                producerFax: isLoggedIn["custom:fax"] || " ",
                                producerPhone:
                                  isLoggedIn["custom:phoneNo."] || " ",
                                producerMobile:
                                  isLoggedIn["custom:mobile"] || " ",
                                user_email_id: user_id,
                                uuid_carrier: `${currentDate}@${uuid}_${peo}+${place}`,
                                date: currentDate,
                                visitDate: visitTimestamp,
                                effective_date: moment(
                                  companyProfile.effectiveDate.value
                                ).format("YYYY-MM-DD"),
                                expiration_date: moment(
                                  companyProfile.expectedExpiryDate.value
                                ).format("YYYY-MM-DD"),
                                prospect_addresses: prospectAddresses,
                                phone_number: companyProfile.phoneNumber.value,
                                company_name: companyProfile.companyName.value,
                                fein: companyProfile.fein.value,
                                liability_limitId: "1000-1000-1000",
                                uuid: uuid,
                                peo: `${peo}+${place}`,
                                // carrier_email_id: data[place][peo][carrier].carrier_email,
                                carrier_location_data: data[place][peo],
                                doc_status: "not_uploaded",
                                currProspectDetails,
                                uwQues: quesList,
                                industries,
                                details,
                                classCodeEligibility:
                                  class_code_object[place][peo],
                                // data[place][peo][carrier].classCodeEligibility,
                              };

                              if (isUwFlow) {
                                tempData.submissionDate = submissionDate;
                                tempData.quoteSubmitted = "true";
                              }

                              if (!stateCarrierData)
                                stateCarrierData =
                                  tempData?.stateCarrierData || {};

                              let _peo = tempData.peo.split("+")[0];
                              let _address = tempData.peo.split("+")[1];

                              if (
                                carrierBasedData[_address] === undefined ||
                                carrierBasedData[_address] === null
                              ) {
                                carrierBasedData[_address] = {};
                              }
                              if (
                                carrierBasedData[_address][_peo] ===
                                  undefined ||
                                carrierBasedData[_address][_peo] === null
                              ) {
                                carrierBasedData[_address][_peo] = {};
                              }
                              carrierBasedData[_address][_peo] = tempData;

                              sortKeyList.push(tempData.uuid_carrier);
                              carrierUserData.push(tempData);
                            }
                          }

                          let userStatusData = {
                            carrierBasedData,
                            stateCarrierData,
                            uuid: sortKeyList,
                          };

                          quoteData = {
                            uuid,
                            date: currentDate,
                          };

                          setContextData("userStatusData", userStatusData);
                          setContextData("sortKeyList", sortKeyList);
                          setContextData("quoteData", quoteData);
                          setContextData("address", address);

                          let prevIndex = 0;
                          let nextIndex = 5;
                          let uploadData;
                          let postUsersDataPromiseList = [];

                          for (
                            let i = 0;
                            i < Math.floor(carrierUserData.length / 5) + 1;
                            i++
                          ) {
                            uploadData = carrierUserData.slice(
                              prevIndex,
                              nextIndex
                            );
                            if (uploadData.length > 0) {
                              postUsersDataPromiseList.push(
                                postUsersData(uploadData)
                                  .then((res) => {})
                                  .catch((error) => {
                                    console.log("error in posting data", error);
                                    stopLoader();
                                  })
                              );
                            }

                            prevIndex = nextIndex;
                            nextIndex = nextIndex + 5;
                          }

                          await Promise.allSettled(
                            postUsersDataPromiseList
                          ).then(async (res) => {
                            let emailID = isLoggedIn.email;
                            let userDetails = {
                              user_email_id: user_id,
                              uwQues: quesList,
                              status: "quote_generated",
                              uploadTimestamp: currentDate,
                              modifiedBy: emailID,
                              quoteData,
                              address,
                              riskProfile: riskProfile,
                              childrenLoc: childrenLoc,
                              companyProfile: companyProfile,
                              emodStatesData: emodStatesData,
                              peoDetails,
                              carrierList,
                            };

                            if (isUwFlow) {
                              userDetails.submissionDate = submissionDate;
                              userDetails.quoteSubmitted = "true";
                            }

                            await userTableData(userDetails, "update")
                              .then((res2) => {
                                setContextData("userData", userDetails);
                              })
                              .catch((err) => {
                                console.log("Error:", err);
                                stopLoader();
                              });
                            setContextData("quoteFactors", quoteFactors);
                            setContextData("getQuoteClicked", true);
                            setFormStage("three");
                            stopLoader();
                          });
                        }
                      }
                    } catch (error) {
                      console.log("error", error);

                      setContextData("quoteFactors", quoteFactors);

                      stopLoader();
                    }
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  stopLoader();
                });
            };

            fetchData(id);
          } else {
            console.log("no carriers available");
            stopLoader();
          }
        })
        .catch((err) => {
          console.log("error in eligibility api", err);
          stopLoader();
        });
    }
  } catch (error) {
    console.log("error", error);
    stopLoader();
  }
}

export function updateQuesList(e, key, value) {
  let { quesList } = this.state;
  let index = e.target.id.split("-")[1];
  quesList[index][key] = value;
  this.setState({ quesList });
}

export function updateAmtrustQuesList(e, key, value) {
  let { amtrustQuesList } = this.state;
  let index = e.target.id.split("-")[1];
  amtrustQuesList[index][key] = value;
  this.setState({ amtrustQuesList });
}

export function updateAddQuesList(e, key, value) {
  let { quesListAdd } = this.state;
  let index = Number(e.target.id.split("-")[1]) - 25;
  quesListAdd[index][key] = value;
  this.setState({ quesListAdd });
}

export function appendAddQuesList(index, questionsList) {
  let { quesListAdd } = this.state;
  questionsList.forEach((question) => {
    quesListAdd[index] = question;
    index++;
  });
  this.setState({ quesListAdd });
}

export function updateEmodRates(i, value, key) {
  let { quesListAdd } = this.state;
  if (!quesListAdd[i].rates) {
    quesListAdd[i].rates = {};
  }
  quesListAdd[i].rates[key] = value;
  this.setState({ quesListAdd });
}
