import React, { useEffect, useState } from "react";
import { useLibertateContext } from "../context/ContextProvider";
import { getUserDataStatus, userTableData } from "../api/commonApi";
import { fetHubspotcompanyContactDetails } from "../api/dashboardApi";
import moment from "moment";
function FetchQuotesData(props) {
  const [userStatusData, setUserStatusData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userHubSpotData, setUserHubSpotData] = useState(null);
  const [alreadyRunning, setAlreadyRunning] = useState(false);

  let libertateContext = useLibertateContext();
  let fetchUserStatusData = (companyId) => {
    try {
      getUserDataStatus(companyId, "quote")
        .then((response) => {
          if (response?.data) {
            setUserStatusData(response.data);
            libertateContext.stopLoader();
          }
        })
        .catch((err) => {
          console.log("error while fetching userStatusData: ", err);
        });
    } catch (error) {
      console.log(error);
      libertateContext.stopLoader();
    }
  };
  let fetchUserData = (companyId) => {

    try {
      libertateContext.startLoader();
      userTableData({ domain: companyId }, "get")
        .then((response) => {
          if (response?.data?.[0]) {
            setUserData(response.data[0]);
            libertateContext.stopLoader();

            if (!userStatusData) {
              fetchUserStatusData(companyId);
            }
          }
          if(response?.data?.length ===0){
            throw new Error("no Data found in userstatus table")
          }
        })
        .catch((err) => {
          if (!userHubSpotData) {
            fetHubspotData(companyId);
          }
          console.log("error while fetching userTableData: ", err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  let fetHubspotData = async (companyId) => {
    // we need to prepare our company profile in our backend and send it to Ui,  TODO
    try {
      let requestBody = { companyId };
     
      let response = await fetHubspotcompanyContactDetails(requestBody);
      let hubSpotContactDetailsEmail =
        response?.data?.prepareContactDetails || {};
        let companyProfile=response?.data?.companyProfile ||{}
      // Update userHubSpotData state with fetched data
      setUserHubSpotData({
        companyProfile,
        email_specific: { ...hubSpotContactDetailsEmail },
      });
  
      libertateContext.stopLoader();
    } catch (error) {
      console.error("Error while fetching HubSpot data: ", error);
      libertateContext.stopLoader();
      // Handle error as needed
    }
  };
  useEffect(() => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let companyId = urlParams.get("user") || sessionStorage.getItem("user");

    if (companyId && !alreadyRunning) {
      setAlreadyRunning(true);
      libertateContext.setCurrentUser(companyId);
      if (!userData) {
        fetchUserData(companyId);
      }
    }
  });
  useEffect(() => {
    if (libertateContext.setUserData && userData) {
      libertateContext.setUserData(userData);
      // we are getting the form stage to two when we have the data in user table
      if (Object.keys(userData).length > 0) {
        libertateContext.setFormStage("two");
      }
    }
  }, [userData]);

  useEffect(() => {
    if (libertateContext.setHubSpotUserData && userHubSpotData) {
      libertateContext.setHubSpotUserData(userHubSpotData);
      // we are getting the form stage to three when we have the data in userStatus table
      if (Object.keys(userHubSpotData).length > 0) {
        libertateContext.setFormStage("one");
      }
    }
  }, [userHubSpotData]);

  useEffect(() => {
    if (libertateContext.setUserStatusData && userStatusData) {
      libertateContext.setUserStatusData(userStatusData);
      // we are getting the form stage to three when we have the data in userStatus table
      if (Object.keys(userStatusData).length > 0) {
        libertateContext.setFormStage("three");
      }
    }
  }, [userStatusData]);

  return <div className="fetch-quote-container">{props.children}</div>;
}

export default FetchQuotesData;
