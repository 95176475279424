import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { PatternFormat } from "react-number-format";
import styles from "./ContactDetails.module.scss";

const ContactDetails = ({
  contactDetailsData,
  deleteContactDetailsHandler,
  updateContactDetails,
  email_specificError,
}) => {
  const [phoneError, setPhoneError] = useState({});
  const [emailIdError, setEmailIdError] = useState({});
  const [isEmailEditable, setIsEmailEditable] = useState({});
  useEffect(() => {
    const initialEmailEditableState = {};
    contactDetailsData &&
      Object.entries(contactDetailsData).forEach(([key, details], index) => {
        if (details?.companyProfile?.emailId?.value) {
          initialEmailEditableState[index] = true;
        } else {
          initialEmailEditableState[index] = false;
        }
      });
    setIsEmailEditable((prevState) => ({
      ...prevState,
      ...initialEmailEditableState,
    }));
  }, []);

  const validatePhoneNumber = (phoneNumber, index) => {
    if (
      phoneNumber &&
      !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phoneNumber)
    ) {
      setPhoneError((prevState) => ({ ...prevState, [index]: true }));
    } else {
      setPhoneError((prevState) => ({ ...prevState, [index]: false }));
    }
  };

  const validEmailIdCheck = (email, index) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailIdError((prevState) => ({ ...prevState, [index]: true }));
    } else {
      // Check for duplicates
      const emailValues = Object.values(contactDetailsData).map(
        (details) => details.companyProfile.emailId.value
      );
      const isDuplicate = emailValues.filter((e) => e === email).length > 1;
      if (isDuplicate) {
        setEmailIdError((prevState) => ({
          ...prevState,
          [index]: "Duplicate email",
        }));
      } else {
        setEmailIdError((prevState) => ({ ...prevState, [index]: false }));
      }
    }
  };

  return (
    <>
      <div id="contact-details-container">
        {Object.keys(contactDetailsData).length > 0 ? (
          Object.entries(contactDetailsData).map(([key, details], index) => (
            <div className="row no-gutters" key={index}>
              <div className="col-lg-3 col-md-6">
                <Form.Group className={styles.form_group}>
                  <label
                    htmlFor={`contact_name_${index}`}
                    className="mandatory"
                  >
                    <b className="font-family-montserrat-semi-bold">
                      Contact Name
                    </b>
                  </label>
                  <input
                    type="text"
                    value={details?.companyProfile?.contact_name?.value || ""}
                    name={"contact_name"}
                    id={`contact_name_${index}`}
                    placeholder="Contact Name"
                    autoComplete="off"
                    onChange={(e) => updateContactDetails(e, index)}
                    style={{
                      border: email_specificError?.[index]?.["contact_name"]
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <p className="errMsg" />
                </Form.Group>
              </div>
              <div className="col-lg-3 col-md-12">
                <Form.Group className={styles.form_group}>
                  <label htmlFor={`email_${index}`} className="mandatory">
                    <b className="font-family-montserrat-semi-bold">Email Id</b>
                  </label>
                  <input
                    type="email"
                    value={details?.companyProfile?.emailId?.value || ""}
                    id={`email_${index}`}
                    placeholder="Email"
                    autoComplete="off"
                    disabled={isEmailEditable[index] ? true : false}
                    onBlur={(event) =>
                      validEmailIdCheck(event.target.value, index)
                    }
                    onChange={(e) => {
                      updateContactDetails(e, index);
                      validEmailIdCheck(e.target.value, index);
                    }}
                    name={"emailId"}
                    className={emailIdError[index] ? styles.inputError : ""}
                    style={{
                      border: email_specificError?.[index]?.["emailId"]
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  {emailIdError[index] && (
                    <p style={{ color: "red" }}>
                      {emailIdError[index] === true
                        ? "Invalid Email ID"
                        : emailIdError[index]}
                    </p>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-3 col-md-12">
                <Form.Group className={styles.form_group}>
                  <label htmlFor={`job_title_${index}`} className="mandatory">
                    <b className="font-family-montserrat-semi-bold">
                      Job Description
                    </b>
                  </label>
                  <input
                    type="text"
                    value={details?.companyProfile?.job_title?.value || ""}
                    id={`job_title_${index}`}
                    placeholder="Job Description"
                    autoComplete="off"
                    name={"job_title"}
                    onChange={(e) => updateContactDetails(e, index)}
                    style={{
                      border: email_specificError?.[index]?.["job_title"]
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <p className="errMsg" />
                </Form.Group>
              </div>
              <div className="col-lg-2 col-md-12">
                <Form.Group className={styles.form_group}>
                  <label htmlFor={`phoneNumber_${index}`} className="mandatory">
                    <b className="font-family-montserrat-semi-bold">
                      Phone Number
                    </b>
                  </label>
                  <PatternFormat
                    id={`phoneNumber_${index}`}
                    type="text"
                    format="(###) ###-####"
                    placeholder="(000) 000-0000"
                    value={details?.companyProfile?.phoneNumber?.value}
                    name={"phoneNumber"}
                    onBlur={(event) =>
                      validatePhoneNumber(event.target.value, index)
                    }
                    onChange={(e) => updateContactDetails(e, index)}
                    autoComplete="off"
                    className={phoneError[index] ? styles.inputError : ""}
                    style={{
                      border: email_specificError?.[index]?.["phoneNumber"]
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  {phoneError[index] && (
                    <p style={{ color: "red" }}>Invalid phone number.</p>
                  )}
                  <p className="errMsg" />
                </Form.Group>
              </div>
              {details?.companyProfile?.newCreateContact?.value ? (
                <div className="col-lg-1 col-md-12">
                  <button
                    type="button"
                    className="rmvLoc btnInvisible transpButton mt-3 btn"
                    onClick={() => deleteContactDetailsHandler(index)}
                  >
                    <img
                      src={require("../../../images/delete4.png")}
                      alt="Delete"
                    />
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          ))
        ) : (
          <p>No contact details available.</p>
        )}
      </div>
    </>
  );
};

export default ContactDetails;
